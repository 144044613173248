import styled from 'styled-components';

export const Container = styled.div`
  .rdt_TableHeadRow,
  .rdt_TableRow {
    div {
      div,
      p {
        white-space: unset;
        width: 100%;
        text-align: center;
      }
    }

    > div:first-child {
      max-width: 150px;
    }
  }

  .menu-table {
    align-items: center;
  }
`;

export const Welcome = styled.div`
  h1 {
    color: #e4e4e4;
  }
  p {
    color: #969696;
  }
`;

export const NameStudentTable = styled.div`
  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
`;

export const Skeleton = styled.div`
  border-radius: 20px;
  background-color: #202020;
  z-index: 0;

  .h-36 {
    height: 36px;
  }

  .img-height {
    height: 40px;
    width: 40px;
  }

  .skeleton {
    background-color: #343434 !important;
    overflow: hidden;
    position: relative;
    border-radius: 20px;
    border: none !important;
    color: transparent !important;
    pointer-events: none;
    display: inline-block;
    box-shadow: none;

    span,
    input {
      color: transparent !important;
    }
    img {
      display: none;
    }

    button {
      display: none;
    }

    :after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        #343434 0%,
        rgba(24, 26, 26, 0) 0%,
        rgba(24, 26, 26, 0) 10%,
        rgba(24, 26, 26, 0.2) 20%,
        rgba(24, 26, 26, 0.5) 40%,
        rgba(24, 26, 26, 0.8) 50%,
        rgba(24, 26, 26, 0.2) 80%,
        rgba(24, 26, 26, 0) 90%,
        rgba(24, 26, 26, 0) 100%
      ) !important;
      animation: glow 1.3s linear infinite;
    }

    @keyframes glow {
      100% {
        transform: translateX(100%);
      }
    }
  }

  @media screen and (max-width: 991px) {
    .w-40 {
      width: 40%;
    }
    .w-15 {
      width: 15%;
    }
  }
`;
