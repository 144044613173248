/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import { lastDayOfMonth } from 'date-fns';

import { useAuth } from '~/hooks/Auth';
import { useLanguage } from '~/hooks/Language';
import api from '~/services/api';

import {
  Container,
  Welcome,
  Values,
  PyramidData,
  Distribution,
} from './styles';
import Pyramid from '~/components/Pyramid';

import franchiseIcon from '~/assets/icons/franchise-icon.svg';
import qualifing from '~/assets/icons/qualifing-icon.svg';
import maxFunding from '~/assets/icons/max-funding-icon.svg';
import fireBall from '~/assets/icons/fire-ball-gray.svg';
import { formatPrice } from '~/utils/format';

interface IProduct {
  id: string;
  name: string;
  price: number;
  slug: string;
}

interface IFranchise {
  franchiseType: string;
  qualifingRounds: number;
  price: number;
  maxFundingUnlocked: string;
}

interface ICrowdfundingGains {
  product_price: string;
  earning: string;
  product: {
    price: string;
    lines: number;
  };
}

interface ICommission {
  id: string;
  earning: string;
  created_at: string;
}

const LinksList: React.FC = () => {
  const { user } = useAuth();
  const { language } = useLanguage();
  const [totalCrowdfundingMembers, setTotalCrowdfundingMembers] = useState(0);
  const [totalCrowdfundingValue, setTotalCrowdfundingValue] = useState('');
  const [franchise, setFranchise] = useState<IFranchise>({} as IFranchise);
  const [productAffiliate, setProductAffiliate] = useState<any>(undefined);
  const [volumeThisMonth, setVolumeThisMonth] = useState('$0.00');
  const [qualifingVolume, setQualifingVolume] = useState('$0.00');
  const [crowdfundingShare, setCrowdfundingShare] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api
      .get(`crowdfunding-affiliates/total-members/${user.affiliate.id}`)
      .then((response) => {
        setTotalCrowdfundingMembers(response.data);
      });

    api.get('crowdfunding/commissions').then((response) => {
      const total = response.data.reduce(
        (acumulador: number, currentValue: ICommission) => {
          return acumulador + parseFloat(currentValue.earning);
        },
        0
      );

      setTotalCrowdfundingValue(formatPrice(total));
    });

    let qualifingRounds = 0;
    let productAffiliateData;
    if (user.affiliate.affiliatesProducts) {
      user.affiliate.affiliatesProducts.forEach((affiliateProduct) => {
        if (affiliateProduct.product.lines > qualifingRounds) {
          qualifingRounds = affiliateProduct.product.lines;
        }
      });

      productAffiliateData = user.affiliate.affiliatesProducts.find(
        (affiliateProduct) => affiliateProduct.product.lines === qualifingRounds
      );
    }

    setProductAffiliate(productAffiliateData);

    api
      .get<IProduct>(`products/students/${productAffiliateData?.product_id}`)
      .then((response) => {
        let price = 0;
        switch (qualifingRounds) {
          case 2:
            price = 240;
            break;
          case 4:
            price = 10000;
            break;
          case 6:
            price = 200000;
            break;
          case 8:
            price = 1000000;
            break;
          default:
            price = 0;
            break;
        }
        const maxFundingUnlocked = formatPrice(price).slice(-15, -3);

        const data: IFranchise = {
          franchiseType: response.data.name,
          qualifingRounds,
          price,
          maxFundingUnlocked,
        };
        setFranchise(data);
      })
      .finally(() => setLoading(false));
  }, [user.affiliate]);

  useEffect(() => {
    if (productAffiliate && Object.keys(franchise).length > 0) {
      const currentDate = new Date();

      api
        .get<ICrowdfundingGains[]>(`crowdfunding-gains/${user.affiliate.id}`, {
          params: {
            initialDate: new Date(
              currentDate.getFullYear(),
              currentDate.getMonth(),
              1,
              0,
              0,
              0
            ),
            endDate: new Date(
              currentDate.getFullYear(),
              currentDate.getMonth(),
              lastDayOfMonth(currentDate).getDate(),
              23,
              59,
              59
            ),
          },
        })
        .then((response) => {
          let volumeThisMonthData = 0;
          let qualifingVolumeData = 0;
          let crowdfundingShareData = 0;

          response.data.forEach((crowdfundingGain) => {
            volumeThisMonthData += parseFloat(crowdfundingGain.product.price);
            qualifingVolumeData += parseFloat(crowdfundingGain.product_price);
            crowdfundingShareData += parseFloat(crowdfundingGain.earning);
          });

          setQualifingVolume(formatPrice(qualifingVolumeData));
          setVolumeThisMonth(formatPrice(volumeThisMonthData));
          setCrowdfundingShare(formatPrice(crowdfundingShareData));
        });
    }
  }, [franchise, productAffiliate, user.affiliate.id]);

  return (
    <Container>
      <>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 p-0">
              <Welcome>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12">
                      <h1 className="h2-md h4 py-4 py-lg-2">
                        {language.crowdfunding.h1}
                      </h1>
                      <p
                        className="mb-4"
                        dangerouslySetInnerHTML={{
                          __html: language.crowdfunding.p_1,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Welcome>
            </div>
          </div>
          <div className="row">
            <Values className="col-xl-6 px-0 pr-xl-2 mb-3 mb-xl-0">
              <div className="bg-gray flex-wrap d-md-flex py-4 py-md-5 py-xl-4 h-100">
                <div className="col-md-3 col-xl-6 mb-xl-4 text-center border-md-right">
                  <p
                    className={`${
                      loading === true ? 'skeleton px-2' : ''
                    } h4 mb-2`}
                  >
                    {totalCrowdfundingMembers}
                  </p>
                  <p className="mb-0 small-md normal-lg dark-gray">
                    {language.crowdfunding.p_2}
                  </p>
                  <hr className="d-md-none my-4 w-25" />
                </div>
                <div className="col-md-3 col-xl-6 mb-xl-4 text-center border-md-right border-right-lg-0">
                  <p
                    className={`${loading === true ? 'skeleton' : ''} h4 mb-2`}
                  >
                    {volumeThisMonth}
                  </p>
                  <p className="mb-0 small-md normal-lg dark-gray">
                    {language.crowdfunding.p_3}
                  </p>
                  <hr className="d-md-none my-4 w-25" />
                </div>
                <div className="col-md-3 col-xl-6 text-center border-md-right">
                  <p
                    className={`${loading === true ? 'skeleton' : ''} h4 mb-2`}
                  >
                    {qualifingVolume}
                  </p>
                  <p className="mb-0 small-md normal-lg dark-gray">
                    {language.crowdfunding.p_4}
                  </p>
                  <hr className="d-md-none my-4 w-25" />
                </div>
                <div className="col-md-3 col-xl-6 text-center">
                  <p
                    className={`${loading === true ? 'skeleton' : ''} h4 mb-2`}
                  >
                    {crowdfundingShare}
                  </p>
                  <p className="mb-0 small-md normal-lg dark-gray">
                    {language.crowdfunding.p_5} (
                    {user.affiliate.crowdfunding_share}%)
                  </p>
                </div>
              </div>
            </Values>
            <Distribution className="col-xl-6 px-0 pl-xl-2">
              <div className="bg-gray flex-wrap d-sm-flex align-items-center p-4 p-xl-3 p-xxl-4 h-100">
                <div className="col-sm-7 col-xxl px-0 px-md-3 text-center text-sm-left">
                  <p className="h6 color">{language.crowdfunding.p_9}</p>
                  <p
                    className={`${
                      loading === true ? 'skeleton' : ''
                    } h3 h2-lg my-4 font-weight-600`}
                  >
                    {totalCrowdfundingValue}
                  </p>
                </div>
                <div className="col-sm-5 col-xxl pr-sm-0 pr-md-3 pr-xl-0 pl-md-0">
                  <div className="bg-box p-4 text-center shadow">
                    <p className="mb-0 font-weight-400">
                      {language.crowdfunding.p_10_1}{' '}
                      <img src={fireBall} alt="Fire Ball" />
                      <b className="text-white">
                        {' '}
                        {language.crowdfunding.p_10_b}
                      </b>{' '}
                      {language.crowdfunding.p_10_2}
                    </p>
                  </div>
                </div>
              </div>
            </Distribution>
          </div>
          <div className="row">
            <PyramidData className="col-12 py-4 p-xl-5 my-3">
              <div className="row">
                <div className="col-12 order-2 px-sm-4 order-lg-1">
                  <div className="row justify-content-end">
                    <div className="col-lg-4 col-xl-5 col-xxl-6">
                      {/* <h2>{language.crowdfunding.h2}</h2> */}
                    </div>
                    <div className="col-md-4 col-lg px-3 px-lg-2 text-center">
                      <div className="p-relative d-flex d-md-block align-items-center h-100 top bg-gray px-2 px-lg-3 px-xxl-2 py-5 py-md-4">
                        <div className="col-4 col-md-12">
                          <img
                            src={franchiseIcon}
                            alt={language.crowdfunding.p_6}
                            className="mb-md-3"
                          />
                        </div>
                        <div className="col-8 col-md-12 px-0">
                          <p className="mb-2 my-md-2 px-4">
                            {language.crowdfunding.p_6}
                          </p>
                          <h4 className="h5 h4-sm font-weight-400 mt-xxl-4">
                            <span
                              className={
                                loading === true ? 'skeleton' : 'd-none'
                              }
                            >
                              ######
                            </span>
                            {franchise.franchiseType}
                          </h4>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 col-lg my-3 my-md-0 px-3 px-lg-2 text-center">
                      <div className="p-relative d-flex d-md-block align-items-center h-100 top bg-gray px-2 py-5 py-md-4">
                        <div className="col-4 col-md-12">
                          <img
                            src={qualifing}
                            alt={language.crowdfunding.p_7}
                            className="mb-md-3"
                          />
                        </div>
                        <div className="col-8 col-md-12 px-0">
                          <p className="mb-2 my-md-2 px-4">
                            {language.crowdfunding.p_7}
                          </p>
                          <h4 className="h5 h4-sm font-weight-400 mt-xxl-4">
                            <span
                              className={
                                loading === true ? 'skeleton' : 'd-none'
                              }
                            >
                              ######
                            </span>
                            {franchise.qualifingRounds}
                          </h4>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 col-lg px-3 px-lg-2 text-center">
                      <div className="p-relative d-flex d-md-block align-items-center h-100 top bg-gray px-2 py-5 py-md-4">
                        <div className="col-4 col-md-12">
                          <img
                            src={maxFunding}
                            alt={language.crowdfunding.p_8}
                            className="mb-md-3"
                          />
                        </div>
                        <div className="col-8 col-md-12 px-0">
                          <p className="mb-2 my-md-2">
                            {language.crowdfunding.p_8}
                          </p>
                          <h4 className="h5 h4-sm font-weight-400 mt-xxl-4">
                            <span
                              className={
                                loading === true ? 'skeleton' : 'd-none'
                              }
                            >
                              ######
                            </span>
                            {franchise.maxFundingUnlocked}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 px-2 px-sm-3 px-xl-2 px-xxl-3 order-1 order-lg-2">
                  <Pyramid linesUnlock={franchise.qualifingRounds} />
                </div>
              </div>
            </PyramidData>
          </div>
        </div>
      </>
    </Container>
  );
};

export default LinksList;
