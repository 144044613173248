/* eslint-disable no-param-reassign */
import React, { useCallback, useEffect, useState } from 'react';
import { FiCopy } from 'react-icons/fi';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';

import { useAuth } from '~/hooks/Auth';
import { useLanguage } from '~/hooks/Language';
import api from '~/services/api';

import {
  Container,
  Search,
  Welcome,
  Card,
  Thumbnail,
  LinkCopy,
  Flag,
  Skeleton,
} from './styles';

import facebook from '~/assets/icons/facebook-icon.svg';
import twitter from '~/assets/icons/twitter-icon.svg';
import whatsapp from '~/assets/icons/whatsapp-circle-icon.svg';
import search from '~/assets/icons/search-icon.svg';
import Textarea from '~/components/Textarea';

interface ILink {
  id?: string;
  description: string;
  image: string;
  thumbnail?: File;
  thumbnail_id?: string;
  link: string;
}

interface IResponseLink {
  id: string;
  description: string;
  link: string;
  thumbnail: {
    id: string;
    thumbnail_url: string;
  };
}

const SocialMedia: React.FC = () => {
  const { user } = useAuth();
  const { language } = useLanguage();
  const [links, setLinks] = useState<ILink[]>([]);
  const [copied, setCopied] = useState(false);
  const [loading, setLoading] = useState(true);
  const [linkSelected, setLinkSelected] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);

    api.get('links/students').then((response) => {
      const data = response.data.map((link: IResponseLink) => ({
        id: link.id,
        description: link.description,
        image: link.thumbnail.thumbnail_url,
        thumbnail_id: link.thumbnail.id,
        link: link.link,
      }));

      setLinks(data);
      setLoading(false);
    });
  }, []);

  const handleClickCopy = useCallback((e, link_id) => {
    const element = e.target.closest('.link').querySelector('input');
    element.select();
    document.execCommand('copy');
    const selection = window.getSelection;
    if (selection) {
      selection()?.removeAllRanges();
      setCopied(true);
      setLinkSelected(link_id);
      setTimeout(() => {
        setCopied(false);
        setLinkSelected('');
      }, 500);
    }
  }, []);

  return (
    <Container>
      <div className="container-fluid container-xxl px-0 px-sm-3">
        <div className="row">
          <div className="col-12 p-0">
            <Welcome>
              <div className="container-fluid">
                <div className="row justify-content-end">
                  <div className="col-12">
                    <h1 className="h3 h2-lg mb-3 mt-4 mt-sm-0">
                      {language.affiliates_promotional_material.h1}
                    </h1>
                    <p className="my-5 font-weight-300">
                      {language.affiliates_promotional_material.p_1_1}
                      <br />
                      {language.affiliates_promotional_material.p_1_2}
                    </p>
                  </div>
                </div>
              </div>
            </Welcome>
          </div>
          {loading && (
            <>
              <Skeleton className="col-md-6 col-lg-4 my-3">
                <Card className="p-3 h-100">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <div className="d-flex align-items-center w-100">
                      <div className="skeleton img-profile" />
                      <p className="skeleton username small mb-0 ml-2">
                        ######### ########## #########
                      </p>
                    </div>
                  </div>
                  <p className="skeleton tinytext small form-control py-1 border-0 mb-2 bg-transparent description-h">
                    Wiserr es una plataforma que ayuda emprendedores a crecer o
                    iniciar su propio negocio de acuerdo con tus necesidades e
                    intereses específicos. Con Wiserr, puedes encontrar
                    educación adaptada a diferentes
                  </p>
                  <div className="skeleton d-flex img-height flex-column justify-content-center align-items-center label-image mt-2 mb-4" />
                  <LinkCopy className="skeleton link rounded-pill d-flex justify-content-between align-items-center py-1 px-3 mt-3 p-relative">
                    <input className="small my-1 py-2 w-100 bg-transparent border-0" />
                  </LinkCopy>
                  <div className="d-flex flex-wrap mt-4 pb-3">
                    <div className="w-100 text-center">
                      <small className="skeleton text-center mb-3">
                        {language.social_media.small}
                      </small>
                    </div>
                    <div className="col px-2 px-sm-3 px-lg-2 px-xl-3">
                      <div className="skeleton h-100 btn-share shadow rounded-pill w-100">
                        <p>####</p>
                      </div>
                    </div>
                    <div className="col px-2 px-sm-3 px-lg-2 px-xl-3">
                      <div className="skeleton h-100 btn-share shadow rounded-pill w-100">
                        <p>####</p>
                      </div>
                    </div>
                    <div className="col px-2 px-sm-3 px-lg-2 px-xl-3">
                      <div className="skeleton h-100 btn-share shadow rounded-pill w-100">
                        <p>####</p>
                      </div>
                    </div>
                  </div>
                </Card>
              </Skeleton>
              <Skeleton className="col-md-6 col-lg-4 my-3">
                <Card className="p-3 h-100">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <div className="d-flex align-items-center w-100">
                      <div className="skeleton img-profile" />
                      <p className="skeleton username small mb-0 ml-2">
                        ######### ########## #########
                      </p>
                    </div>
                  </div>
                  <p className="skeleton tinytext small form-control py-1 border-0 mb-2 bg-transparent description-h">
                    Wiserr es una plataforma que ayuda emprendedores a crecer o
                    iniciar su propio negocio de acuerdo con tus necesidades e
                    intereses específicos. Con Wiserr, puedes encontrar
                    educación adaptada a diferentes
                  </p>
                  <div className="skeleton d-flex img-height flex-column justify-content-center align-items-center label-image mt-2 mb-4" />
                  <LinkCopy className="skeleton link rounded-pill d-flex justify-content-between align-items-center py-1 px-3 mt-3 p-relative">
                    <input className="small my-1 py-2 w-100 bg-transparent border-0" />
                  </LinkCopy>
                  <div className="d-flex flex-wrap mt-4 pb-3">
                    <div className="w-100 text-center">
                      <small className="skeleton text-center mb-3">
                        {language.social_media.small}
                      </small>
                    </div>
                    <div className="col px-2 px-sm-3 px-lg-2 px-xl-3">
                      <div className="skeleton h-100 btn-share shadow rounded-pill w-100">
                        <p>####</p>
                      </div>
                    </div>
                    <div className="col px-2 px-sm-3 px-lg-2 px-xl-3">
                      <div className="skeleton h-100 btn-share shadow rounded-pill w-100">
                        <p>####</p>
                      </div>
                    </div>
                    <div className="col px-2 px-sm-3 px-lg-2 px-xl-3">
                      <div className="skeleton h-100 btn-share shadow rounded-pill w-100">
                        <p>####</p>
                      </div>
                    </div>
                  </div>
                </Card>
              </Skeleton>
              <Skeleton className="col-md-6 col-lg-4 my-3">
                <Card className="p-3 h-100">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <div className="d-flex align-items-center w-100">
                      <div className="skeleton img-profile" />
                      <p className="skeleton username small mb-0 ml-2">
                        ######### ########## #########
                      </p>
                    </div>
                  </div>
                  <p className="skeleton tinytext small form-control py-1 border-0 mb-2 bg-transparent description-h">
                    Wiserr es una plataforma que ayuda emprendedores a crecer o
                    iniciar su propio negocio de acuerdo con tus necesidades e
                    intereses específicos. Con Wiserr, puedes encontrar
                    educación adaptada a diferentes
                  </p>
                  <div className="skeleton d-flex img-height flex-column justify-content-center align-items-center label-image mt-2 mb-4" />
                  <LinkCopy className="skeleton link rounded-pill d-flex justify-content-between align-items-center py-1 px-3 mt-3 p-relative">
                    <input className="small my-1 py-2 w-100 bg-transparent border-0" />
                  </LinkCopy>
                  <div className="d-flex flex-wrap mt-4 pb-3">
                    <div className="w-100 text-center">
                      <small className="skeleton text-center mb-3">
                        {language.social_media.small}
                      </small>
                    </div>
                    <div className="col px-2 px-sm-3 px-lg-2 px-xl-3">
                      <div className="skeleton h-100 btn-share shadow rounded-pill w-100">
                        <p>####</p>
                      </div>
                    </div>
                    <div className="col px-2 px-sm-3 px-lg-2 px-xl-3">
                      <div className="skeleton h-100 btn-share shadow rounded-pill w-100">
                        <p>####</p>
                      </div>
                    </div>
                    <div className="col px-2 px-sm-3 px-lg-2 px-xl-3">
                      <div className="skeleton h-100 btn-share shadow rounded-pill w-100">
                        <p>####</p>
                      </div>
                    </div>
                  </div>
                </Card>
              </Skeleton>
            </>
          )}
          {links.map((link, index) => (
            <div key={index} className="col-md-6 col-lg-4 my-3">
              <Card className="p-3 h-100">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <div className="d-flex align-items-center w-100">
                    <img
                      src={user.avatar.avatar_url}
                      alt="Avatar"
                      className="img-profile"
                    />
                    <p className="username small mb-0 ml-2">
                      <b>{user.username || user.name}</b>{' '}
                      {language.social_media.p}
                    </p>
                  </div>
                </div>
                <p className="tinytext small form-control py-1 border-0 mb-2 bg-transparent description-h">
                  {link.description}
                </p>
                <Thumbnail
                  src={link.image}
                  className="d-flex flex-column justify-content-center align-items-center label-image my-4"
                />
                <LinkCopy className="link rounded-pill d-flex justify-content-between align-items-center py-1 px-3 mt-3 p-relative">
                  <input
                    className="small my-1 py-2 w-100 bg-transparent border-0"
                    value={`${link.link}/i/${user.referral_code.slice(1)}`}
                    readOnly
                  />
                  <button
                    type="button"
                    className="border-0 bg-transparent"
                    onClick={(e) => handleClickCopy(e, link.id)}
                  >
                    <FiCopy size={24} color="#CBCCCE" />
                  </button>
                  <Flag
                    show={copied && linkSelected === link.id}
                    className="px-3 pt-1 p-absolute"
                  >
                    <p className="mb-0 text-green">{language.footer.copy}</p>
                  </Flag>
                </LinkCopy>
                <div className="d-flex flex-wrap mt-4 pb-3">
                  <small className="w-100 text-center mb-3">
                    {language.social_media.small}
                  </small>
                  <div className="col px-2 px-sm-3 px-lg-2 px-xl-3">
                    <FacebookShareButton
                      quote={link.description}
                      url={`${link.link}?referrer=${user.referral_code}`}
                      className="btn-share shadow rounded-pill w-100"
                    >
                      <img
                        src={facebook}
                        alt={language.social_media.img_1}
                        className="my-2"
                      />
                    </FacebookShareButton>
                  </div>
                  <div className="col px-2 px-sm-3 px-lg-2 px-xl-3">
                    <TwitterShareButton
                      title={`${link.description}\n`}
                      url={`${link.link}?referrer=${user.referral_code}`}
                      className="btn-share shadow rounded-pill w-100"
                    >
                      <img
                        src={twitter}
                        alt={language.social_media.img_2}
                        className="my-2"
                      />
                    </TwitterShareButton>
                  </div>
                  <div className="col px-2 px-sm-3 px-lg-2 px-xl-3">
                    <WhatsappShareButton
                      title={link.description}
                      separator={`\n`}
                      url={`${link.link}?referrer=${user.referral_code}`}
                      className="btn-share shadow rounded-pill w-100"
                    >
                      <img
                        src={whatsapp}
                        alt={language.social_media.img_3}
                        className="my-2"
                      />
                    </WhatsappShareButton>
                  </div>
                </div>
              </Card>
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default SocialMedia;
