import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useLayoutEffect,
} from 'react';
import Carousel from 'react-elastic-carousel';

import api from '~/services/api';
import { useAuth } from '~/hooks/Auth';

import { Container, Img, Slot, CardProfile, ImgCard, Skeleton } from './styles';
import fireBall from '~/assets/icons/franchise-blue-icon.svg';
import more from '~/assets/icons/more-profiles-icon.svg';
import arrow from '~/assets/icons/arrow-right.svg';

interface ICrowdfundingAffiliatesResponse {
  id: string;
  affiliate: {
    student: {
      name: string;
      referral_code: string;
      address: string;
      avatar: {
        avatar_url: string;
      };
    };
    product: {
      id: string;
      name: string;
    };
  };
}

interface ICrowdfundingAffiliates {
  id: string;
  name: string;
  referral_code: string;
  address: string;
  avatar: string;
  franchise: string;
}

interface IPyramidProps {
  linesUnlock: number;
}

interface IUser {
  id: string;
  avatar: string;
  name: string;
  referrer_code: string;
  product: string;
}

const Pyramid: React.FC<IPyramidProps> = ({ linesUnlock }) => {
  const { user } = useAuth();
  const [crowdfundingDatas, setCrowdfundingDatas] = useState<
    ICrowdfundingAffiliates[][]
  >([]);
  const [userSelectedOld, setUserSelectedOld] = useState<IUser>({} as IUser);
  const [userSelected, setUserSelected] = useState<IUser>({} as IUser);
  const [inEnd, setInEnd] = useState(false);
  const [loading, setLoading] = useState(true);

  const [width, setWidth] = useState(0);
  const [show9, setShow9] = useState(5);
  const [show27, setShow27] = useState(7);
  const [show81, setShow81] = useState(9);
  const [show243, setShow243] = useState(11);
  const [show729, setShow729] = useState(13);
  const [show2187, setShow2187] = useState(15);
  const [show6561, setShow6561] = useState(17);
  const [cardWidth, setCardWidth] = useState(220);
  const [cardHeight, setCardHeight] = useState(580);

  const updateSize = useCallback(() => {
    setWidth(window.innerWidth);
  }, []);

  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [updateSize]);

  useEffect(() => {
    if (width >= 2300) {
      setCardWidth(680);
      setCardHeight(620);
      setShow9(5);
      setShow27(7);
      setShow81(9);
      setShow243(11);
      setShow729(13);
      setShow2187(14);
      setShow6561(14);
    } else if (width >= 1800) {
      setCardWidth(550);
      setCardHeight(650);
      setShow9(5);
      setShow27(7);
      setShow81(9);
      setShow243(11);
      setShow729(13);
      setShow2187(14);
      setShow6561(14);
    } else if (width >= 1600) {
      setCardWidth(330);
      setCardHeight(620);
      setShow9(5);
      setShow27(7);
      setShow81(9);
      setShow243(11);
      setShow729(13);
      setShow2187(14);
      setShow6561(14);
    } else if (width >= 1500) {
      setCardWidth(250);
      setCardHeight(620);
      setShow9(5);
      setShow27(7);
      setShow81(9);
      setShow243(11);
      setShow729(13);
      setShow2187(14);
      setShow6561(14);
    } else if (width >= 1400) {
      setCardWidth(160);
      setCardHeight(620);
      setShow9(5);
      setShow27(7);
      setShow81(9);
      setShow243(11);
      setShow729(12);
      setShow2187(12);
      setShow6561(12);
    } else if (width >= 1300) {
      setCardWidth(220);
      setCardHeight(600);
      setShow9(5);
      setShow27(7);
      setShow81(9);
      setShow243(11);
      setShow729(12);
      setShow2187(12);
      setShow6561(12);
    } else if (width >= 1200) {
      setCardWidth(200);
      setCardHeight(610);
      setShow9(5);
      setShow27(7);
      setShow81(9);
      setShow243(10);
      setShow729(10);
      setShow2187(10);
      setShow6561(10);
    } else if (width >= 1100) {
      setCardWidth(170);
      setCardHeight(750);
      setShow9(5);
      setShow27(7);
      setShow81(9);
      setShow243(10);
      setShow729(10);
      setShow2187(10);
      setShow6561(10);
    } else if (width >= 992) {
      setCardWidth(120);
      setCardHeight(740);
      setShow9(5);
      setShow27(7);
      setShow81(9);
      setShow243(9);
      setShow729(9);
      setShow2187(9);
      setShow6561(9);
    } else if (width >= 768) {
      setCardWidth(70);
      setCardHeight(620);
      setShow9(5);
      setShow27(7);
      setShow81(7);
      setShow243(7);
      setShow729(7);
      setShow2187(7);
      setShow6561(7);
    } else if (width >= 575) {
      setCardWidth(60);
      setCardHeight(950);
      setShow9(5);
      setShow27(5);
      setShow81(5);
      setShow243(5);
      setShow729(5);
      setShow2187(5);
      setShow6561(5);
    } else if (width >= 450) {
      setCardWidth(50);
      setCardHeight(1020);
      setShow9(3);
      setShow27(3);
      setShow81(3);
      setShow243(3);
      setShow729(3);
      setShow2187(3);
      setShow6561(3);
    } else if (width >= 400) {
      setCardWidth(50);
      setCardHeight(1100);
      setShow9(3);
      setShow27(3);
      setShow81(3);
      setShow243(3);
      setShow729(3);
      setShow2187(3);
      setShow6561(3);
    } else if (width >= 350) {
      setCardWidth(50);
      setCardHeight(1150);
      setShow9(3);
      setShow27(3);
      setShow81(3);
      setShow243(3);
      setShow729(3);
      setShow2187(3);
      setShow6561(3);
    } else {
      setCardWidth(52);
      setCardHeight(1220);
      setShow9(3);
      setShow27(3);
      setShow81(3);
      setShow243(3);
      setShow729(3);
      setShow2187(3);
      setShow6561(3);
    }
  }, [width]);

  useEffect(() => {
    api
      .get<ICrowdfundingAffiliatesResponse[]>(
        `crowdfunding-affiliates/${user.affiliate.id}`,
        {
          params: {
            skip: 0,
            take: 3,
          },
        }
      )
      .then(async (response) => {
        const responseSecondRow = await api.get<
          ICrowdfundingAffiliatesResponse[]
        >(`crowdfunding-affiliates/${user.affiliate.id}`, {
          params: {
            skip: 3,
            take: 5,
          },
        });

        const responseThirdRow = await api.get<
          ICrowdfundingAffiliatesResponse[]
        >(`crowdfunding-affiliates/${user.affiliate.id}`, {
          params: {
            skip: 12,
            take: 7,
          },
        });

        const responseFourthRow = await api.get<
          ICrowdfundingAffiliatesResponse[]
        >(`crowdfunding-affiliates/${user.affiliate.id}`, {
          params: {
            skip: 39,
            take: 9,
          },
        });

        const responseFifthRow = await api.get<
          ICrowdfundingAffiliatesResponse[]
        >(`crowdfunding-affiliates/${user.affiliate.id}`, {
          params: {
            skip: 120,
            take: 11,
          },
        });

        const responseSixthRow = await api.get<
          ICrowdfundingAffiliatesResponse[]
        >(`crowdfunding-affiliates/${user.affiliate.id}`, {
          params: {
            skip: 363,
            take: 13,
          },
        });

        const responseSeventhRow = await api.get<
          ICrowdfundingAffiliatesResponse[]
        >(`crowdfunding-affiliates/${user.affiliate.id}`, {
          params: {
            skip: 1092,
            take: 15,
          },
        });

        const responseEighthRow = await api.get<
          ICrowdfundingAffiliatesResponse[]
        >(`crowdfunding-affiliates/${user.affiliate.id}`, {
          params: {
            skip: 3279,
            take: 17,
          },
        });

        const firstRowData: ICrowdfundingAffiliates[] = response.data.map(
          (crowdfundingAffiliate) => ({
            id: crowdfundingAffiliate.id,
            name: crowdfundingAffiliate.affiliate.student.name,
            address: crowdfundingAffiliate.affiliate.student.address,
            referral_code:
              crowdfundingAffiliate.affiliate.student.referral_code,
            avatar: crowdfundingAffiliate.affiliate.student.avatar.avatar_url,
            franchise: crowdfundingAffiliate.affiliate.product
              ? crowdfundingAffiliate.affiliate.product.name
              : '',
          })
        );

        const secondRowData: ICrowdfundingAffiliates[] = responseSecondRow.data.map(
          (crowdfundingAffiliate) => ({
            id: crowdfundingAffiliate.id,
            name: crowdfundingAffiliate.affiliate.student.name,
            address: crowdfundingAffiliate.affiliate.student.address,
            referral_code:
              crowdfundingAffiliate.affiliate.student.referral_code,
            avatar: crowdfundingAffiliate.affiliate.student.avatar.avatar_url,
            franchise: crowdfundingAffiliate.affiliate.product
              ? crowdfundingAffiliate.affiliate.product.name
              : '',
          })
        );

        const thirdRowData: ICrowdfundingAffiliates[] = responseThirdRow.data.map(
          (crowdfundingAffiliate) => ({
            id: crowdfundingAffiliate.id,
            name: crowdfundingAffiliate.affiliate.student.name,
            address: crowdfundingAffiliate.affiliate.student.address,
            referral_code:
              crowdfundingAffiliate.affiliate.student.referral_code,
            avatar: crowdfundingAffiliate.affiliate.student.avatar.avatar_url,
            franchise: crowdfundingAffiliate.affiliate.product
              ? crowdfundingAffiliate.affiliate.product.name
              : '',
          })
        );

        const fourthRowData: ICrowdfundingAffiliates[] = responseFourthRow.data.map(
          (crowdfundingAffiliate) => ({
            id: crowdfundingAffiliate.id,
            name: crowdfundingAffiliate.affiliate.student.name,
            address: crowdfundingAffiliate.affiliate.student.address,
            referral_code:
              crowdfundingAffiliate.affiliate.student.referral_code,
            avatar: crowdfundingAffiliate.affiliate.student.avatar.avatar_url,
            franchise: crowdfundingAffiliate.affiliate.product
              ? crowdfundingAffiliate.affiliate.product.name
              : '',
          })
        );

        const fifthRowData: ICrowdfundingAffiliates[] = responseFifthRow.data.map(
          (crowdfundingAffiliate) => ({
            id: crowdfundingAffiliate.id,
            name: crowdfundingAffiliate.affiliate.student.name,
            address: crowdfundingAffiliate.affiliate.student.address,
            referral_code:
              crowdfundingAffiliate.affiliate.student.referral_code,
            avatar: crowdfundingAffiliate.affiliate.student.avatar.avatar_url,
            franchise: crowdfundingAffiliate.affiliate.product
              ? crowdfundingAffiliate.affiliate.product.name
              : '',
          })
        );

        const sixthRowData: ICrowdfundingAffiliates[] = responseSixthRow.data.map(
          (crowdfundingAffiliate) => ({
            id: crowdfundingAffiliate.id,
            name: crowdfundingAffiliate.affiliate.student.name,
            address: crowdfundingAffiliate.affiliate.student.address,
            referral_code:
              crowdfundingAffiliate.affiliate.student.referral_code,
            avatar: crowdfundingAffiliate.affiliate.student.avatar.avatar_url,
            franchise: crowdfundingAffiliate.affiliate.product
              ? crowdfundingAffiliate.affiliate.product.name
              : '',
          })
        );

        const seventhRowData: ICrowdfundingAffiliates[] = responseSeventhRow.data.map(
          (crowdfundingAffiliate) => ({
            id: crowdfundingAffiliate.id,
            name: crowdfundingAffiliate.affiliate.student.name,
            address: crowdfundingAffiliate.affiliate.student.address,
            referral_code:
              crowdfundingAffiliate.affiliate.student.referral_code,
            avatar: crowdfundingAffiliate.affiliate.student.avatar.avatar_url,
            franchise: crowdfundingAffiliate.affiliate.product
              ? crowdfundingAffiliate.affiliate.product.name
              : '',
          })
        );

        const eighthRowData: ICrowdfundingAffiliates[] = responseEighthRow.data.map(
          (crowdfundingAffiliate) => ({
            id: crowdfundingAffiliate.id,
            name: crowdfundingAffiliate.affiliate.student.name,
            address: crowdfundingAffiliate.affiliate.student.address,
            referral_code:
              crowdfundingAffiliate.affiliate.student.referral_code,
            avatar: crowdfundingAffiliate.affiliate.student.avatar.avatar_url,
            franchise: crowdfundingAffiliate.affiliate.product
              ? crowdfundingAffiliate.affiliate.product.name
              : '',
          })
        );

        const data: ICrowdfundingAffiliates[][] = [
          firstRowData,
          secondRowData,
          thirdRowData,
          fourthRowData,
          fifthRowData,
          sixthRowData,
          seventhRowData,
          eighthRowData,
        ];

        setCrowdfundingDatas(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [user, user.affiliate.id]);

  const breakPoints = useMemo(() => [{ width: 0 }], []);

  const handleMouseEnterMyProfile = useCallback((e) => {
    const element = e.target.closest('.border-gray');
    element.classList.add('active');
  }, []);

  const handleMouseLeaveMyProfile = useCallback((e) => {
    const element = e.target.closest('.border-gray');
    element.classList.remove('active');
  }, []);

  const handleMouseEnter = useCallback(
    (e, data: ICrowdfundingAffiliates) => {
      const element = e.target.closest('.border-gray');
      const elementCardProfile = document.getElementById('cardProfile');
      if (elementCardProfile && element) {
        const dataUser = {
          id: data.id,
          avatar: data.avatar,
          name: data.name,
          product: data.franchise,
          referrer_code: data.referral_code,
        };

        setUserSelectedOld(dataUser);
        setUserSelected(dataUser);

        const { top, left } = e.target.getBoundingClientRect();
        const y = top + window.pageYOffset;
        const x = left + window.pageXOffset;
        elementCardProfile.style.top = `${y - cardHeight}px`;
        elementCardProfile.style.left = `${x - cardWidth}px`;
        elementCardProfile.style.opacity = '1';
        elementCardProfile.style.visibility = 'visible';
      }
    },
    [cardHeight, cardWidth]
  );

  const handleMouseEnterCard = useCallback(
    (e) => {
      e.target.style.opacity = '1';
      e.target.style.visibility = 'visible';
      setUserSelected(userSelectedOld);
    },
    [userSelectedOld]
  );

  const handleMouseLeave = useCallback(() => {
    const elementCardProfile = document.getElementById('cardProfile');
    if (elementCardProfile) {
      elementCardProfile.style.opacity = '0';
      elementCardProfile.style.visibility = 'hidden';
      setUserSelected({} as IUser);
    }
  }, []);

  const renderSlots = useCallback(
    (quantity, line) => {
      const slots = [];
      for (let i = 0; i < quantity; i += 1) {
        if (line > linesUnlock) {
          slots.push(<Slot className="border-gray" />);
        } else {
          slots.push(<Slot className="border-gray p-relative" />);
        }
      }
      return slots;
    },
    [linesUnlock]
  );

  const handleNextEnd = useCallback(
    async (index, maxSlots, qtdLoadingSlots = 3) => {
      const newCrowdfundingDatas = crowdfundingDatas.slice();
      if (newCrowdfundingDatas[index].length < maxSlots) {
        const totalSlots = newCrowdfundingDatas[index].length + qtdLoadingSlots;
        let qtdSlots = 0;
        if (totalSlots <= maxSlots) {
          qtdSlots = qtdLoadingSlots;
        } else {
          const diffSlots = totalSlots - maxSlots;
          qtdSlots = qtdLoadingSlots - diffSlots;
        }

        let skip = 0;

        if (index === 1) {
          skip =
            newCrowdfundingDatas[index - 1].length +
            newCrowdfundingDatas[index].length;
        }
        if (index === 2) {
          skip =
            newCrowdfundingDatas[index - 2].length +
            newCrowdfundingDatas[index - 1].length +
            newCrowdfundingDatas[index].length;
        }
        if (index === 3) {
          skip =
            newCrowdfundingDatas[index - 3].length +
            newCrowdfundingDatas[index - 2].length +
            newCrowdfundingDatas[index - 1].length +
            newCrowdfundingDatas[index].length;
        }
        if (index === 4) {
          skip =
            newCrowdfundingDatas[index - 4].length +
            newCrowdfundingDatas[index - 3].length +
            newCrowdfundingDatas[index - 2].length +
            newCrowdfundingDatas[index - 1].length +
            newCrowdfundingDatas[index].length;
        }
        if (index === 5) {
          skip =
            newCrowdfundingDatas[index - 5].length +
            newCrowdfundingDatas[index - 4].length +
            newCrowdfundingDatas[index - 3].length +
            newCrowdfundingDatas[index - 2].length +
            newCrowdfundingDatas[index - 1].length +
            newCrowdfundingDatas[index].length;
        }
        if (index === 6) {
          skip =
            newCrowdfundingDatas[index - 6].length +
            newCrowdfundingDatas[index - 5].length +
            newCrowdfundingDatas[index - 4].length +
            newCrowdfundingDatas[index - 3].length +
            newCrowdfundingDatas[index - 2].length +
            newCrowdfundingDatas[index - 1].length +
            newCrowdfundingDatas[index].length;
        }
        if (index === 7) {
          skip =
            newCrowdfundingDatas[index - 7].length +
            newCrowdfundingDatas[index - 6].length +
            newCrowdfundingDatas[index - 5].length +
            newCrowdfundingDatas[index - 4].length +
            newCrowdfundingDatas[index - 3].length +
            newCrowdfundingDatas[index - 2].length +
            newCrowdfundingDatas[index - 1].length +
            newCrowdfundingDatas[index].length;
        }

        const response = await api.get<ICrowdfundingAffiliatesResponse[]>(
          `crowdfunding-affiliates/${user.affiliate.id}`,
          {
            params: {
              skip,
              take: qtdSlots,
            },
          }
        );

        if (response.data.length > 0) {
          const data: ICrowdfundingAffiliates[] = response.data.map(
            (crowdfundingAffiliate) => ({
              id: crowdfundingAffiliate.id,
              name: crowdfundingAffiliate.affiliate.student.name,
              address: crowdfundingAffiliate.affiliate.student.address,
              referral_code:
                crowdfundingAffiliate.affiliate.student.referral_code,
              avatar: crowdfundingAffiliate.affiliate.student.avatar.avatar_url,
              franchise: crowdfundingAffiliate.affiliate.product
                ? crowdfundingAffiliate.affiliate.product.name
                : '',
            })
          );

          newCrowdfundingDatas[index].push(...data);
          setCrowdfundingDatas(newCrowdfundingDatas);
        } else {
          setInEnd(true);
        }
      }
    },
    [crowdfundingDatas, user.affiliate.id]
  );

  return (
    <>
      <CardProfile
        id="cardProfile"
        onMouseEnter={handleMouseEnterCard}
        onMouseLeave={handleMouseLeave}
        className="p-absolute text-center p-4 d-flex flex-column justify-content-center"
      >
        <ImgCard
          src={userSelected.avatar}
          size={95}
          borderWidth={4}
          borderRadius={30}
          className="mx-auto mb-3"
        />
        <h4 className="h5 font-weight-400">{userSelected.name}</h4>
        <p className="h6 font-weight-300 mb-0">{userSelected.referrer_code}</p>
        <hr />
        <div className="d-flex align-items-center justify-content-center">
          {/* <img src={fireBall} alt="Franchise" className="fireBall" /> */}
          <p className="h5 font-weight-400 ml-1 mb-0">{userSelected.product}</p>
        </div>
        {/* <p className="text-golden">Japan, English</p> */}
      </CardProfile>
      <Container className="pb-3">
        <div className="d-flex pl-lg-2 mb-4 row-profiles align-items-center justify-content-center justify-content-lg-start">
          <div className="border-gray first-profile p-relative ">
            <Img
              src={user.avatar.avatar_url}
              size={95}
              borderWidth={4}
              borderRadius={30}
              hasShadow
              className={loading === true ? 'skeleton' : ''}
            />
            <CardProfile
              myProfile
              onMouseEnter={handleMouseEnterMyProfile}
              onMouseLeave={handleMouseLeaveMyProfile}
              className="p-absolute text-center p-3 position"
            >
              <img src={user.avatar.avatar_url} alt={user.name} />
              <h4>{user.name}</h4>
              <p>{user.referral_code}</p>
              {false && (
                <>
                  <hr />
                  <div className="d-flex align-items-center justify-content-center mb-2">
                    <img
                      src={fireBall}
                      alt="Franchise"
                      className="fireBall border-0"
                    />
                    <p className="ml-1 mb-0">IM Academy</p>
                  </div>
                  <p className="text-golden">Japan, English</p>
                </>
              )}
            </CardProfile>
          </div>
        </div>
        {loading && (
          <Skeleton>
            <div className="d-flex align-items-center">
              <div className="square-3">
                <Carousel
                  isRTL={false}
                  pagination={false}
                  showArrows={false}
                  itemsToShow={3}
                  breakPoints={breakPoints}
                >
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                </Carousel>
              </div>
              <p className="skeleton h5 h4-sm h4-xl number-color mb-0 ml-2 px-2">
                3
              </p>
            </div>
            <div className="d-flex align-items-center">
              <div className="square-9">
                <Carousel
                  isRTL={false}
                  pagination={false}
                  showArrows={false}
                  itemsToShow={show9}
                  breakPoints={breakPoints}
                >
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                </Carousel>
              </div>
              <p className="skeleton h5 h4-sm h4-xl number-color mb-0 ml-2 px-2">
                9
              </p>
            </div>
            <div className="d-flex align-items-center">
              <div className="square-27">
                <Carousel
                  isRTL={false}
                  pagination={false}
                  showArrows={false}
                  itemsToShow={show27}
                  breakPoints={breakPoints}
                >
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                </Carousel>
              </div>
              <p className="skeleton h5 h4-sm h4-xl number-color mb-0 ml-2 px-2">
                27
              </p>
            </div>
            <div className="d-flex align-items-center">
              <div className="square-81">
                <Carousel
                  isRTL={false}
                  pagination={false}
                  showArrows={false}
                  itemsToShow={show81}
                  breakPoints={breakPoints}
                >
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                </Carousel>
              </div>
              <p className="skeleton h5 h4-sm h4-xl number-color mb-0 ml-2 px-2">
                81
              </p>
            </div>
            <div className="d-flex align-items-center">
              <div className="square-243">
                <Carousel
                  isRTL={false}
                  pagination={false}
                  showArrows={false}
                  itemsToShow={show243}
                  breakPoints={breakPoints}
                >
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                </Carousel>
              </div>
              <p className="skeleton h5 h4-sm h4-xl number-color mb-0 ml-2 px-2">
                243
              </p>
            </div>
            <div className="d-flex align-items-center">
              <div className="square-729">
                <Carousel
                  isRTL={false}
                  pagination={false}
                  showArrows={false}
                  itemsToShow={show729}
                  breakPoints={breakPoints}
                >
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                </Carousel>
              </div>
              <p className="skeleton h5 h4-sm h4-xl number-color mb-0 ml-2 px-2">
                729
              </p>
            </div>
            <div className="d-flex align-items-center">
              <div className="square-2187">
                <Carousel
                  isRTL={false}
                  pagination={false}
                  showArrows={false}
                  itemsToShow={show2187}
                  breakPoints={breakPoints}
                >
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                </Carousel>
              </div>
              <p className="skeleton h5 h4-sm h4-xl number-color mb-0 ml-2 px-2">
                2187
              </p>
            </div>
            <div className="d-flex align-items-center">
              <div className="square-6561">
                <Carousel
                  isRTL={false}
                  pagination={false}
                  showArrows={false}
                  itemsToShow={show6561}
                  breakPoints={breakPoints}
                >
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                  <Slot className="p-relative">
                    <Img
                      src="/"
                      className="skeleton"
                      size={60}
                      borderWidth={0}
                      borderRadius={20}
                    />
                  </Slot>
                </Carousel>
              </div>
              <p className="skeleton h5 h4-sm h4-xl number-color mb-0 ml-2 px-2">
                6561
              </p>
            </div>
          </Skeleton>
        )}
        {crowdfundingDatas.map((crowdfundingData, index) => (
          <div key={index} className="d-flex align-items-center">
            {index === 0 && (
              <>
                <div className="square-3">
                  <Carousel
                    isRTL={false}
                    pagination={false}
                    showArrows={false}
                    itemsToShow={3}
                    // enableAutoPlay
                    // autoPlaySpeed={1500}
                    breakPoints={breakPoints}
                  >
                    {crowdfundingData.map((crowdfunding) => (
                      <Slot
                        key={crowdfunding.id}
                        className="border-gray p-relative"
                      >
                        <Img
                          src={crowdfunding.avatar}
                          size={60}
                          borderWidth={0}
                          borderRadius={20}
                          onMouseEnter={(e) =>
                            handleMouseEnter(e, crowdfunding)
                          }
                          onMouseLeave={handleMouseLeave}
                        />
                      </Slot>
                    ))}
                    {crowdfundingData.length < 3 &&
                      renderSlots(3 - crowdfundingData.length, index + 1)}
                  </Carousel>
                </div>
                <p className="h5 h4-sm h4-xl number-color mb-0 ml-2">3</p>
              </>
            )}
            {index === 1 && (
              <>
                <div className="square-9">
                  <Carousel
                    isRTL={false}
                    pagination={false}
                    showArrows={false}
                    itemsToShow={show9}
                    onNextEnd={() => handleNextEnd(index, 9)}
                    // enableAutoPlay
                    // autoPlaySpeed={1500}
                    breakPoints={breakPoints}
                  >
                    {crowdfundingData.map((crowdfunding) => (
                      <Slot
                        key={crowdfunding.id}
                        className="border-gray p-relative"
                      >
                        <Img
                          src={crowdfunding.avatar}
                          size={60}
                          borderWidth={0}
                          borderRadius={20}
                          onMouseEnter={(e) =>
                            handleMouseEnter(e, crowdfunding)
                          }
                          onMouseLeave={handleMouseLeave}
                        />
                      </Slot>
                    ))}
                    {crowdfundingData.length < 9 &&
                      renderSlots(5 - crowdfundingData.length, index + 1)}
                    {!inEnd &&
                      crowdfundingData.length >= show9 &&
                      crowdfundingData.length < 9 && (
                        <Slot className="border-gray p-relative">
                          <div
                            className="spinner-border text-light"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </Slot>
                      )}
                  </Carousel>
                </div>
                <img src={more} alt="More" className="w-4 d-none d-md-block" />
                <img src={arrow} alt="More" className="d-md-none" />
                <p className="h5 h4-sm h4-xl number-color mb-0 ml-2">9</p>
              </>
            )}
            {index === 2 && (
              <>
                <div className="square-27">
                  <Carousel
                    isRTL={false}
                    pagination={false}
                    showArrows={false}
                    itemsToShow={show27}
                    // enableAutoPlay
                    // autoPlaySpeed={1500}
                    breakPoints={breakPoints}
                    onNextEnd={() => handleNextEnd(index, 27, 6)}
                  >
                    {crowdfundingData.map((crowdfunding) => (
                      <Slot
                        key={crowdfunding.id}
                        className="border-gray p-relative"
                      >
                        <Img
                          src={crowdfunding.avatar}
                          size={60}
                          borderWidth={0}
                          borderRadius={20}
                          onMouseEnter={(e) =>
                            handleMouseEnter(e, crowdfunding)
                          }
                          onMouseLeave={handleMouseLeave}
                        />
                      </Slot>
                    ))}
                    {crowdfundingData.length < 27 &&
                      renderSlots(7 - crowdfundingData.length, index + 1)}
                    {!inEnd &&
                      crowdfundingData.length >= show27 &&
                      crowdfundingData.length < 27 && (
                        <Slot className="border-gray p-relative">
                          <div
                            className="spinner-border text-light"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </Slot>
                      )}
                  </Carousel>
                </div>
                <img src={more} alt="More" className="w-4 d-none d-md-block" />
                <img src={arrow} alt="More" className="d-md-none" />
                <p className="h5 h4-sm h4-xl number-color mb-0 ml-2">27</p>
              </>
            )}
            {index === 3 && (
              <>
                <div className="square-81">
                  <Carousel
                    isRTL={false}
                    pagination={false}
                    showArrows={false}
                    itemsToShow={show81}
                    // enableAutoPlay
                    // autoPlaySpeed={1500}
                    breakPoints={breakPoints}
                    onNextEnd={() => handleNextEnd(index, 81, 10)}
                  >
                    {crowdfundingData.map((crowdfunding) => (
                      <Slot
                        key={crowdfunding.id}
                        className="border-gray p-relative"
                      >
                        <Img
                          src={crowdfunding.avatar}
                          size={60}
                          borderWidth={0}
                          borderRadius={20}
                          onMouseEnter={(e) =>
                            handleMouseEnter(e, crowdfunding)
                          }
                          onMouseLeave={handleMouseLeave}
                        />
                      </Slot>
                    ))}
                    {crowdfundingData.length < 81 &&
                      renderSlots(9 - crowdfundingData.length, index + 1)}
                    {!inEnd &&
                      crowdfundingData.length >= show81 &&
                      crowdfundingData.length < 81 && (
                        <Slot className="border-gray p-relative">
                          <div
                            className="spinner-border text-light"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </Slot>
                      )}
                  </Carousel>
                </div>
                <img src={more} alt="More" className="w-4 d-none d-md-block" />
                <img src={arrow} alt="More" className="d-md-none" />
                <p className="h5 h4-sm h4-xl number-color mb-0 ml-2">81</p>
              </>
            )}
            {index === 4 && (
              <>
                <div className="square-243">
                  <Carousel
                    isRTL={false}
                    pagination={false}
                    showArrows={false}
                    itemsToShow={show243}
                    // enableAutoPlay
                    // autoPlaySpeed={1500}
                    breakPoints={breakPoints}
                    onNextEnd={() => handleNextEnd(index, 243, 10)}
                  >
                    {crowdfundingData.map((crowdfunding) => (
                      <Slot
                        key={crowdfunding.id}
                        className="border-gray p-relative"
                      >
                        <Img
                          src={crowdfunding.avatar}
                          size={60}
                          borderWidth={0}
                          borderRadius={20}
                          onMouseEnter={(e) =>
                            handleMouseEnter(e, crowdfunding)
                          }
                          onMouseLeave={handleMouseLeave}
                        />
                      </Slot>
                    ))}
                    {crowdfundingData.length < 243 &&
                      renderSlots(11 - crowdfundingData.length, index + 1)}
                    {!inEnd &&
                      crowdfundingData.length >= show243 &&
                      crowdfundingData.length < 243 && (
                        <Slot className="border-gray p-relative">
                          <div
                            className="spinner-border text-light"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </Slot>
                      )}
                  </Carousel>
                </div>
                <img src={more} alt="More" className="w-4 d-none d-md-block" />
                <img src={arrow} alt="More" className="d-md-none" />
                <p className="h5 h4-sm h4-xl number-color mb-0 ml-2">243</p>
              </>
            )}
            {index === 5 && (
              <>
                <div className="square-729">
                  <Carousel
                    isRTL={false}
                    pagination={false}
                    showArrows={false}
                    itemsToShow={show729}
                    // enableAutoPlay
                    // autoPlaySpeed={1500}
                    breakPoints={breakPoints}
                    onNextEnd={() => handleNextEnd(index, 729, 10)}
                  >
                    {crowdfundingData.map((crowdfunding) => (
                      <Slot
                        key={crowdfunding.id}
                        className="border-gray p-relative"
                      >
                        <Img
                          src={crowdfunding.avatar}
                          size={60}
                          borderWidth={0}
                          borderRadius={20}
                          onMouseEnter={(e) =>
                            handleMouseEnter(e, crowdfunding)
                          }
                          onMouseLeave={handleMouseLeave}
                        />
                      </Slot>
                    ))}
                    {crowdfundingData.length < 729 &&
                      renderSlots(13 - crowdfundingData.length, index + 1)}
                    {!inEnd &&
                      crowdfundingData.length >= show729 &&
                      crowdfundingData.length < 729 && (
                        <Slot className="border-gray p-relative">
                          <div
                            className="spinner-border text-light"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </Slot>
                      )}
                  </Carousel>
                </div>
                <img src={more} alt="More" className="w-4 d-none d-md-block" />
                <img src={arrow} alt="More" className="d-md-none" />
                <p className="h5 h4-sm h4-xl number-color mb-0 ml-2">729</p>
              </>
            )}
            {index === 6 && (
              <>
                {/* <div style={{ width: 1170 }}> */}

                <div className="square-2187">
                  <Carousel
                    isRTL={false}
                    pagination={false}
                    showArrows={false}
                    itemsToShow={show2187}
                    // itemsToShow={15}
                    // enableAutoPlay
                    // autoPlaySpeed={1500}
                    breakPoints={breakPoints}
                    onNextEnd={() => handleNextEnd(index, 2187, 10)}
                  >
                    {crowdfundingData.map((crowdfunding) => (
                      <Slot
                        key={crowdfunding.id}
                        className="border-gray p-relative"
                      >
                        <Img
                          src={crowdfunding.avatar}
                          size={60}
                          borderWidth={0}
                          borderRadius={20}
                          onMouseEnter={(e) =>
                            handleMouseEnter(e, crowdfunding)
                          }
                          onMouseLeave={handleMouseLeave}
                        />
                      </Slot>
                    ))}
                    {crowdfundingData.length < 2187 &&
                      renderSlots(15 - crowdfundingData.length, index + 1)}
                    {!inEnd &&
                      crowdfundingData.length >= show2187 &&
                      crowdfundingData.length < 2187 && (
                        <Slot className="border-gray p-relative">
                          <div
                            className="spinner-border text-light"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </Slot>
                      )}
                  </Carousel>
                </div>

                <img src={more} alt="More" className="w-4 d-none d-md-block" />
                <img src={arrow} alt="More" className="d-md-none" />
                <p className="h5 h4-sm h4-xl number-color mb-0 ml-2">2,187</p>
              </>
            )}
            {index === 7 && (
              <>
                {/* <div style={{ width: 1326 }}> */}
                <div className="square-6561">
                  <Carousel
                    isRTL={false}
                    pagination={false}
                    showArrows={false}
                    itemsToShow={show6561}
                    // itemsToShow={17}
                    // enableAutoPlay
                    // autoPlaySpeed={1500}
                    breakPoints={breakPoints}
                    onNextEnd={() => handleNextEnd(index, 6561, 10)}
                  >
                    {crowdfundingData.map((crowdfunding) => (
                      <Slot
                        key={crowdfunding.id}
                        className="border-gray p-relative"
                      >
                        <Img
                          src={crowdfunding.avatar}
                          size={60}
                          borderWidth={0}
                          borderRadius={20}
                          onMouseEnter={(e) =>
                            handleMouseEnter(e, crowdfunding)
                          }
                          onMouseLeave={handleMouseLeave}
                        />
                      </Slot>
                    ))}
                    {crowdfundingData.length < 6561 &&
                      renderSlots(17 - crowdfundingData.length, index + 1)}
                    {!inEnd &&
                      crowdfundingData.length >= show6561 &&
                      crowdfundingData.length < 6561 && (
                        <Slot className="border-gray p-relative">
                          <div
                            className="spinner-border text-light"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </Slot>
                      )}
                  </Carousel>
                </div>
                <img src={more} alt="More" className="w-4 d-none d-md-block" />
                <img src={arrow} alt="More" className="d-md-none" />
                <p className="h5 h4-sm h4-xl number-color mb-0 ml-2">6,561</p>
              </>
            )}
          </div>
        ))}
      </Container>
    </>
  );
};

export default Pyramid;
