/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useMemo,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';

interface ILanguage {
  sign_in: {
    h1: string;
    h2: string;
    p: string;
    p_2: string;
    label_1: string;
    label_2: string;
    a: string;
    a_2: string;
    button: string;
    erro_1: string;
    erro_2: string;
    erro_3: string;
    erro_4: string;
    img_1: string;
    img_2: string;
    img_3: string;
    img_4: string;
  };
  header: {
    small_1: string;
    small_2: string;
    small_3_1: string;
    small_3_2: string;
    small_4: string;
    small_5: string;
    small_6_1: string;
    small_6_2: string;
    small_7: string;
    small_8: string;
    button_1: string;
    button_2: string;
    small_9: string;
  };
  dashboard: {
    h1: string;
    h3_1: string;
    h3_2: string;
    input: string;
    button: string;
    p_1: string;
    img_1: string;
    p_2: string;
    img_2: string;
    p_3: string;
    img_3: string;
    p_4: string;
    img_4: string;
    label_1: string;
    label_2: string;
  };
  commission_history: {
    h1: string;
    h2: string;
    th_1: string;
    th_2: string;
    th_3: string;
    th_4: string;
    th_5: string;
    th_6: string;
    p_1: string;
    p_2_1: string;
    p_2_2: string;
    button_1: string;
    button_2: string;
  };
  trainings: {
    h1: string;
    input: string;
  };
  training: {
    button: string;
    h2: string;
    p: string;
    h4: string;
    h3: string;
    placeholder: string;
  };
  social_media: {
    h1: string;
    p: string;
    small: string;
    img_1: string;
    img_2: string;
    img_3: string;
  };
  crowdfunding: {
    h1: string;
    p_1: string;
    p_2: string;
    p_3: string;
    p_4: string;
    p_5: string;
    h2: string;
    p_6: string;
    p_7: string;
    p_8: string;
    p_9: string;
    p_10_1: string;
    p_10_b: string;
    p_10_2: string;
    button_1: string;
    button_2: string;
    button_3: string;
    button_4: string;
  };
  footer: {
    h2: string;
    p_1: string;
    h4_1: string;
    h4_2: string;
    h4_3: string;
    h4_4: string;
    b: string;
    p_2: string;
    label_1: string;
    label_2: string;
    button_1: string;
    copy: string;
    wallet_value: string;
    required_value: string;
  };
  password_component: {
    button_1: string;
    button_2: string;
  };
  table: {
    input: string;
    p_1: string;
    p_2_1: string;
    p_2_2: string;
    button_1: string;
    button_2: string;
    a: string;
  };
  wallet: {
    h1: string;
    img_1: string;
    img_2: string;
    p_1_1: string;
    p_1_2: string;
    p_2_1: string;
    p_2_2: string;
    navLink_1: string;
    navLink_2: string;
    h5: string;
    button_1: string;
    button_2: string;
    button_3: string;
    button_4: string;
    button_5: string;
    small_1: string;
    modal_title: string;
    modal_p_1: string;
    modal_p_2: string;
    modal_p_3: string;
    modal_p_4: string;
    modal_p_5: string;
    modal_span_1: string;
    modal_span_2: string;
    modal_span_3: string;
    modal_small: string;
    modal_button_1: string;
    modal_button_2: string;
    modal_value: string;
    modal_value_2: string;
    modal_value_3: string;
    modal_address: string;
    modal_authentication: string;
    modal_authentication_2: string;
    modal_error: string;
    modal_success_1: string;
    modal_success_2: string;
    type: string;
    date: string;
    amount: string;
    status: string;
    card: string;
  };
  update_warning: {
    h1: string;
    p: string;
  };
  affiliates: {
    h1: string;
    h3_1: string;
    h3_2: string;
    h3_3: string;
    p_1: string;
    p_2: string;
    p_3: string;
    a: string;
  };
  affiliates_crowdfunding: {
    h1: string;
    p_1: string;
    p_2: string;
    p_3: string;
  };
  affiliates_crowdfunding_list: {
    col_1: string;
    col_2: string;
    col_3: string;
    col_4: string;
    col_5: string;
    col_6: string;
    button: string;
    title: string;
    label: string;
    span: string;
  };
  affiliates_promotional_material: {
    h1: string;
    p_1_1: string;
    p_1_2: string;
    placeholder_1: string;
    button_1: string;
    h2: string;
    button_2: string;
    button_3: string;
    placeholder_2: string;
    p_2: string;
    small: string;
    error: string;
  };
  affiliates_training: {
    h1: string;
    placeholder_1: string;
    p_1: string;
    p_2: string;
  };
  affiliates_categories: {
    h2: string;
    nav: string;
  };
  my_referrals: {
    h1: string;
    p_1: string;
    p_2: string;
    p_3: string;
    joined: string;
    student: string;
    affiliate: string;
    owner: string;
  };
  team_view: {
    p_1: string;
    p_2: string;
    p_3: string;
    p_4: string;
  };
}

interface LanguageContextData {
  language: ILanguage;
  handleSelectIdiom(idiom: string): void;
  idiom: string;
}

export const LanguageContext = createContext<LanguageContextData>(
  {} as LanguageContextData
);

export const LanguageProvider: React.FC = ({ children }) => {
  const location = useLocation();
  const history = useHistory();
  const [idiom, setIdiom] = useState(() => {
    let lang = location.pathname.substr(1, 2);
    if (lang !== 'en' && lang !== 'es') {
      lang = localStorage.getItem('@Membership:language') || 'es';
    }
    const element = document.getElementsByTagName('html');
    element[0].lang = lang;
    return lang;
  });

  const handleSelectIdiom = useCallback((idiomSelected) => {
    setIdiom(idiomSelected);
    const element = document.getElementsByTagName('html');
    element[0].lang = idiomSelected;
  }, []);

  const language = useMemo(() => {
    const checkIdiom = localStorage.getItem('@Membership:language');
    if (checkIdiom !== idiom) {
      const lang = location.pathname.substr(1, 2);
      if (lang !== 'en' && lang !== 'es') {
        console.log('aqui');
        console.log(idiom);
        console.log(location.pathname);
        history.push(`${process.env.PUBLIC_URL}/${idiom}${location.pathname}`);
      } else {
        console.log('aqui2');
        console.log(idiom);
        console.log(location.pathname.substr(3));
        history.push(
          `${process.env.PUBLIC_URL}/${idiom}${location.pathname.substr(3)}`
        );
      }
    }

    localStorage.setItem('@Membership:language', idiom);

    return require(`./languages/${idiom}`);
  }, [history, idiom, location.pathname]);

  return (
    <LanguageContext.Provider value={{ language, handleSelectIdiom, idiom }}>
      {children}
    </LanguageContext.Provider>
  );
};

export function useLanguage(): LanguageContextData {
  const context = useContext(LanguageContext);

  if (!context) {
    throw new Error('useLanguage must be used within an LanguageProvider');
  }

  return context;
}
