import styled from 'styled-components';

export const Container = styled.div`
  .justify-content-wallet {
    justify-content: end !important;
  }
  .bg-gray {
    background: #202020;
    border-radius: 20px;

    .border-right {
      border-color: #393c3d !important;
    }

    h2 {
      color: #dadada;
    }

    .input {
      border: 1.5px solid #393c3d;
      box-sizing: border-box;
      border-radius: 15px;
      height: 40px;

      input {
        color: #efefef !important;
      }
    }

    button.btn-gradient {
      border-radius: 15px;
    }
  }

  .table {
    .rdt_TableHeadRow,
    .rdt_TableRow {
      > div:nth-child(2),
      > div:nth-child(4) {
        max-width: 175px;
        width: 175px;
      }
    }
  }

  .contact {
    cursor: pointer;

    input {
      color: #8c8c8c;
      font-size: 13px;
      background-color: transparent;
      border: none;
    }
  }

  @media screen and (min-width: 1400px) {
    .table-xxl {
      .spacer {
        display: none;
      }

      /* .date-and-export {
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
      } */
    }

    .tableData > div:last-child {
      max-height: 350px;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
`;

export const Welcome = styled.div`
  h1 {
    color: #dadada;
  }

  p {
    color: #969696;
  }

  .btn-month {
    background: #242526;
    border: 1px solid rgba(225, 225, 225, 0.2);

    span {
      color: #8c8c8c;
    }
  }
`;

export const Option = styled.div`
  .Link:hover {
    text-decoration: none;
  }

  .Active {
    color: white;
    border-bottom: solid 2px white;
  }

  .Disable {
    color: #484848;
  }
`;

export const Cards = styled.div`
  .Cards {
    padding: 0;
  }

  .Card {
    background-color: #1d2023;
    border-radius: 20px;
    height: 133px;
  }

  .Card .Title {
    color: #ffffff;
  }

  .Card .Value {
    color: #6fcf97;
    font-size: 20px;
  }

  .Card .Description {
    color: #a09999;
  }

  @media screen and (max-width: 991px) {
    .Card {
      width: 158px;
      height: 133px;
      margin-right: 5px;
    }

    .Card .Title {
      font-size: 11px;
    }
    .Card .Description {
      font-size: 10px;
    }
    .Card .Value {
      font-size: 18px;
      white-space: nowrap;
    }
  }

  @media screen and (min-width: 1200px) {
    .Card .Value {
      font-size: 25px;
    }
  }

  @media screen and (min-width: 992px) {
    .Card .Title {
      justify-content: start;
    }
  }
`;

export const CV = styled.div`
  .CV {
    background-color: #1d2023;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .CV .TitleCv {
    white-space: nowrap;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .CV .ImgCv {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .CV .CardCV {
    border: solid 1px #4f4f4f;
    background-color: #222527;
    border-radius: 20px;
  }

  .CV .CardCV .TitleCardCV {
    color: white;
    font-size: 14px;
  }

  .CV .CardCV .ValueCardCV {
    color: #10c3fc;
    font-size: 24px;
  }

  @media screen and (max-width: 576px) {
    .CV {
    }

    .CV .CardCV {
    }

    .CV .ImgCv img {
      width: 40px;
    }

    .CV .CardCV .ValueCardCV {
      font-size: 18px;
    }
  }

  @media screen and (max-width: 1200px) {
    .CV .CardCV .ValueCardCV {
      font-size: 20px;
    }

    .CV .CardCV .TitleCardCV {
      font-size: 12px;
    }
  }
`;

export const Box = styled.div`
  .Box {
    background-color: #222527;
    margin: 5%;
    border-radius: 20px;
    padding: 5%;
  }

  .Box .TitleBox {
    color: white;
    display: table;
    margin: 0 auto;
  }

  .Box .ValueBox {
    display: table;
    margin: 0 auto;
    color: white;
    font-weight: bolder;
    font-size: 30px;
    margin-bottom: 5%;
  }

  .Box .TimeBox {
    color: #828282;
    margin-top: 1%;
    margin-bottom: 5%;
  }

  .Box .Active {
    color: white;
    font-weight: bold;
  }

  .MonthBox {
    margin-top: 1%;
    margin-bottom: 5%;
  }

  .MonthBox .Active {
    border-radius: 15px;
    background-color: #202020;
    border: solid 2px;
  }

  .Box .WithDrawBox {
    background-color: #18191a;
    border-radius: 20px;
    margin: 3%;
  }

  .Box .WithDrawBox .TitleWithDrawBox {
    color: white;
    font-weight: bold;
  }

  .Box .WithDrawBox .ValueWithDrawBox {
    color: white;
    font-weight: bolder;
    font-size: 20px;
  }

  .Box .WithDrawBox .ButtonWithDrawBox {
    background-color: #242526;
    color: white;
  }

  .Box .WithDrawBox .ButtonBox {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const Board = styled.div`
  background-color: #242526;
  border-radius: 20px;
  width: 100%;

  .skeleton {
    background-color: #343434 !important;
    overflow: hidden;
    position: relative;
    border-radius: 20px;
    border: none !important;
    color: transparent !important;
    pointer-events: none;
    display: table;
    box-shadow: none;

    span,
    input {
      color: transparent !important;
    }
    img {
      display: none;
    }

    button {
      display: none;
    }

    :after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        #343434 0%,
        rgba(24, 26, 26, 0) 0%,
        rgba(24, 26, 26, 0) 10%,
        rgba(24, 26, 26, 0.2) 20%,
        rgba(24, 26, 26, 0.5) 40%,
        rgba(24, 26, 26, 0.8) 50%,
        rgba(24, 26, 26, 0.2) 80%,
        rgba(24, 26, 26, 0) 90%,
        rgba(24, 26, 26, 0) 100%
      ) !important;
      animation: glow 1.3s linear infinite;
    }

    @keyframes glow {
      100% {
        transform: translateX(100%);
      }
    }
  }

  .BoardImg {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Title {
    text-align: center;
    margin-top: 10%;
    color: #828282;
    font-size: 12px;
  }

  .Value {
    text-align: center;
    color: #cbccce;
  }

  @media screen and (max-width: 576px) {
    .BoardImg img {
      width: 30px;
    }

    .Value {
      font-size: 17px;
    }
  }

  @media screen and (min-width: 768px) {
    .BoardImg img {
      width: 40px;
    }
  }

  @media screen and (min-width: 1600px) {
  }
`;
