import styled from 'styled-components';

interface IPrice {
  withdrawal: boolean;
}

export const Container = styled.div`
  div {
    color: #b1b1b1;
  }

  input.inputDate {
    padding: 8px 10px !important;
  }

  a.inputDate,
  input.inputDate {
    background: #242526 !important;
  }

  .size-wallet {
    font-size: 12px !important;
  }

  .link-group {
    z-index: 10;

    a {
      color: #616161;
      text-decoration: none;
    }

    .active {
      color: #cbccce;
    }
  }

  .rdt_TableHead,
  .rdt_TableBody,
  .rdt_TableRow {
    min-width: 0px !important;
  }

  .rdt_TableHeadRow,
  .rdt_TableRow {
    justify-content: space-between;
  }

  .rdt_TableCol,
  .rdt_TableCell {
    width: 180px;
    max-width: 180px;
  }

  @media screen and (min-width: 450px) {
    input.inputDate {
      width: 120px !important;
    }

    a.inputDate {
      width: 70px !important;
      padding: 5px 20px !important;
    }
  }
`;

export const Price = styled.p<IPrice>`
  color: ${(props) => (props.withdrawal ? '#FF1A50' : '#B1B1B1')};
  font-style: ${(props) => (props.withdrawal ? 'italic' : 'normal')};
`;

export const DeleteButton = styled.button`
  border: none;
  background-color: transparent;
`;

export const Skeleton = styled.div`
  .skeleton {
    background-color: #343434 !important;
    overflow: hidden;
    position: relative;
    border-radius: 20px;
    border: none !important;
    color: transparent !important;
    pointer-events: none;
    display: inline-block;
    box-shadow: none;

    span,
    input {
      color: transparent !important;
    }
    img {
      display: none;
    }

    button {
      display: none;
    }

    :after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        #343434 0%,
        rgba(24, 26, 26, 0) 0%,
        rgba(24, 26, 26, 0) 10%,
        rgba(24, 26, 26, 0.2) 20%,
        rgba(24, 26, 26, 0.5) 40%,
        rgba(24, 26, 26, 0.8) 50%,
        rgba(24, 26, 26, 0.2) 80%,
        rgba(24, 26, 26, 0) 90%,
        rgba(24, 26, 26, 0) 100%
      ) !important;
      animation: glow 1.3s linear infinite;
    }

    @keyframes glow {
      100% {
        transform: translateX(100%);
      }
    }
  }
`;
