import React, { useCallback, useState, useRef, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

import { format, parseISO } from 'date-fns';
import getValidationErros from '~/utils/getValidationsErrors';
import { useAuth } from '~/hooks/Auth';
import { useLanguage } from '~/hooks/Language';

import {
  Container,
  Profile,
  Social,
  CopyAlert,
  Avatar,
  GiftCard,
  BuyGiftCard,
} from './styles';
import InputMask from '~/components/InputMask';
import Input from '~/components/Input';

import api from '~/services/api';
import copy from '~/assets/icons/copy.svg';
import { formatPrice } from '~/utils/format';

interface buyGiftFormData {
  value: string;
}

interface IWalletHistory {
  type: string;
  date: string;
  amount: string;
  withdrawal?: boolean;
}

const Info: React.FC = () => {
  const {
    user,
    setWalletPrice,
    walletHistory,
    setWalletHistory,
    giftCards,
    setGiftCards,
  } = useAuth();
  const { language } = useLanguage();
  const buyFormRef = useRef<FormHandles>(null);
  const [copied, setCopied] = useState(false);
  const [giftCard, setGiftCard] = useState('');
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setGiftCard('');
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [location.pathname]);

  const copyUrl = useCallback(() => {
    const el = document.createElement('textarea');
    el.value = `${process.env.REACT_APP_URL}/book/i/${user.referral_code.slice(
      1
    )}`;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  }, [user.referral_code]);

  const copyGiftCard = useCallback((giftCardData) => {
    const el = document.createElement('textarea');
    el.value = giftCardData;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  }, []);

  const handleClick = useCallback(async () => {
    try {
      const formData = {
        price: 300,
        product_id: process.env.REACT_APP_PRODUCT_GIFT,
      };
      const response = await api.post('payments/gift-cards/create', formData);
      setGiftCard(response.data.gift_card.hash);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleSubmitBuyGiftCard = useCallback(
    async (data: buyGiftFormData) => {
      try {
        buyFormRef.current?.setErrors({});
        const schema = Yup.object().shape({
          value: Yup.string().required(language.footer.required_value),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const responseWallet = await api.get('wallets/affiliates');

        const value = parseFloat(data.value.replace('$', '').replace(/,/g, ''));

        if (value > responseWallet.data.amount) {
          throw new Yup.ValidationError('');
        }

        const formData = {
          price: value,
          wallet_id: responseWallet.data.id,
        };
        const response = await api.post('payments/gift-cards/create', formData);
        const dataWalletHistory: IWalletHistory = {
          type: response.data.walletHistory.type,
          date: format(
            parseISO(response.data.walletHistory.created_at),
            'yyyy-MM-dd'
          ),
          amount: formatPrice(response.data.walletHistory.amount),
          withdrawal: response.data.walletHistory.amount < 0,
        };

        const giftCreated = {
          id: response.data.gift_card.id,
          status: 'Unused',
          card: response.data.gift_card.hash,
          amount: formatPrice(response.data.gift_card.price),
        };

        setGiftCards([giftCreated, ...giftCards]);
        setWalletPrice(responseWallet.data.amount - value);
        setGiftCard(response.data.gift_card.hash);
        setWalletHistory([dataWalletHistory, ...walletHistory]);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          let errors = getValidationErros(error);
          if (Object.keys(errors).length === 0) {
            errors = {
              value: `${language.footer.wallet_value}`,
            };
          }

          buyFormRef.current?.setErrors(errors);
        } else {
          Swal.fire('Opss...', language.sign_in.erro_4, 'error');
        }
      }
    },
    [
      giftCards,
      language.footer.required_value,
      language.footer.wallet_value,
      language.sign_in.erro_4,
      setGiftCards,
      setWalletHistory,
      setWalletPrice,
      walletHistory,
    ]
  );

  return (
    <Container className="container container-xl-fluid px-3 px-sm-0 px-xl-3 d-md-flex d-xl-block px-xl-4 mb-4 mb-lg-0">
      <div className="mt-3 w-100 mt-xl-5 d-md-flex d-xl-block justify-content-around align-items-center bg-user py-4 py-xxl-5 px-3 pl-md-2 pr-0 px-xl-4">
        <Profile className="text-center w-md-50 w-lg-auto">
          <div className="w-100 p-relative mb-4">
            <Avatar
              src={user.avatar.avatar_url}
              className={loading === true ? 'skeleton rounded-circle' : ''}
            />
          </div>

          <h2 className={`${loading && 'skeleton'} h5 mb-4`}>
            {user.name.trim()}
          </h2>
        </Profile>
        <div className="ml-lg-4 ml-xl-0 text-center text-md-left text-xl-center">
          <h2 className="h5 mt-3 mt-xl-5 text-lg-center">
            {language.footer.h2}
          </h2>
          <p className="color-gray mt-3 mt-xl-5 mb-2">{language.footer.p_1}</p>
          <button className="btn-copy" type="button" onClick={copyUrl}>
            {user.referral_code}
            <img src={copy} alt="copy" className="ml-2" />
          </button>
        </div>
      </div>
      <BuyGiftCard
        className={`mt-3 ml-md-3 ml-xl-0 mb-xl-0 w-40 ${
          location.pathname.match('wallet') ? '' : 'd-none'
        }`}
      >
        <div className="container">
          <div className="row text-center">
            <div className="col-12 titleBuyGift py-2">
              <h4 className="py-1 my-1">{language.footer.h4_3}</h4>
            </div>
            <div className="col-12 px-3 text-left contentBuyGift">
              <Form
                ref={buyFormRef}
                onSubmit={handleSubmitBuyGiftCard}
                className="my-2"
              >
                <div className="mb-3">
                  <label className="mt-3">{language.footer.label_1}</label>
                  <InputMask
                    kind="money"
                    options={{ unit: '$', delimiter: ',', separator: '.' }}
                    name="value"
                  />
                </div>
                <div className="mb-3">
                  <label className="mt-2">{language.footer.label_2}</label>
                  <Input
                    name="number"
                    value={giftCard}
                    disabled
                    className="d-none"
                  />
                  <button
                    className="btn-copy mb-3"
                    type="button"
                    onClick={() => copyGiftCard(giftCard)}
                    disabled={!giftCard}
                  >
                    <span className="d-block w-100">{giftCard}</span>
                    <img src={copy} alt="copy" className="ml-2" />
                  </button>
                </div>
                <button
                  type="submit"
                  className="mb-3 w-100 py-3 font-weight-600"
                >
                  {language.footer.button_1}
                </button>
              </Form>
            </div>
          </div>
        </div>
      </BuyGiftCard>
      <GiftCard
        className={`mt-3 ml-md-3 ml-xl-0 mb-xl-0 w-40 ${
          location.pathname === '/dashboard' ||
          location.pathname === '/es/dashboard' ||
          location.pathname === '/en/dashboard'
            ? ''
            : 'd-none'
        }`}
      >
        <div className="container">
          <div className="row text-center">
            <div className="col-12 titleGift py-2">
              <h4 className="py-1 my-1">{language.footer.h4_4}</h4>
            </div>
            <div className="col-12 px-3 contentGift">
              <Form
                onSubmit={() => {
                  console.log('aqui');
                }}
                className="my-3"
              >
                <button
                  className="btn-copy mb-3"
                  type="button"
                  onClick={() => copyGiftCard(giftCard)}
                  disabled={!giftCard}
                >
                  <span className="d-block w-100">{giftCard}</span>
                  <img src={copy} alt="copy" className="ml-2" />
                </button>
                <button
                  type="button"
                  onClick={handleClick}
                  className="mb-3 w-100 py-3 font-weight-600"
                >
                  {language.footer.button_1}
                </button>
              </Form>
            </div>
          </div>
        </div>
      </GiftCard>
      <CopyAlert show={copied} className="alert alert-success">
        <p className="mb-0">{language.footer.copy}</p>
      </CopyAlert>
    </Container>
  );
};

export default Info;
