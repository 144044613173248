/* eslint-disable react/jsx-wrap-multilines */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import DataTable, {
  IDataTableColumn,
  IDataTableStyles,
} from 'react-data-table-component';

import { MdFindInPage } from 'react-icons/md';
import { CSVLink } from 'react-csv';
import { useLocation } from 'react-router-dom';
import { useLanguage } from '~/hooks/Language';

import { TableContent, Pagination, NotFound } from './styles';
import InputDate from '~/components/InputDate';

import magnifier from '~/assets/icons/magnifier.svg';
import table from '~/assets/icons/table-icon.svg';

interface IData {
  [key: string]: any;
}
interface ITableProps {
  title?: string;
  showTitle?: boolean;
  data: IData[];
  columns: IDataTableColumn[];
  style?: IDataTableStyles;
  searchable?: boolean;
  onSearch?(value: string): void;
  date?: boolean;
  initialDate?: Date;
  finalDate?: Date;
  onChangeStartDate?(date: Date): void;
  onChangeLastDate?(date: Date): void;
  exportable?: boolean;
  pagination?: boolean;
  onRowClicked?(e: IData): void;
}

const Table: React.FC<ITableProps> = ({
  title,
  showTitle,
  data,
  columns,
  style,
  searchable,
  onSearch,
  date,
  initialDate,
  finalDate,
  onChangeStartDate,
  onChangeLastDate,
  exportable,
  pagination,
  onRowClicked,
}) => {
  const [startDate, setStartDate] = useState(
    initialDate || new Date(new Date().getFullYear(), 0, 1)
  );
  const [lastDate, setLastDate] = useState(
    finalDate || new Date(new Date().getFullYear(), 11, 31)
  );
  const [totalData, setTotalData] = useState(0);
  const [partialData, setPartialData] = useState(0);
  const [selectedPage, setSelectedPage] = useState(1);
  const [firstButton, setFirstButton] = useState(2);
  const [centerButton, setCenterButton] = useState(3);
  const [lastButton, setLastButton] = useState(4);
  const { language } = useLanguage();
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  const customStyles: IDataTableStyles = useMemo(() => {
    return {
      noData: {
        style: {
          backgroundColor: '#202020',
          color: '#dadada',
          fontWeigth: 'bold',
          fontSize: '2rem',
        },
      },
      header: {
        style: {
          backgroundColor: '#202020',
          color: '#dadada',
        },
      },
      headRow: {
        style: {
          border: 'none',
        },
      },
      rows: {
        style: {
          backgroundColor: '#202020',
          '&:not(:last-of-type)': {
            border: 'none',
          },
        },
      },
      headCells: {
        style: {
          backgroundColor: '#202020',
          color: '#dadada',
        },
        activeSortStyle: {
          color: '#dadada',
          '&:focus': {
            outline: 'none',
          },
          '&:hover:not(:focus)': {
            color: '#dadada',
          },
        },
        inactiveSortStyle: {
          color: '#dadada',
          '&:focus': {
            outline: 'none',
            color: '#dadada',
          },
          '&:hover': {
            color: '#dadada',
          },
        },
      },
      cells: {
        style: {
          color: '#8c8c8c',
          backgroundColor: '#202020',
        },
      },
      contextMenu: {
        style: {
          color: '#dadada',
        },
      },
      action: {
        button: '#8c8c8c',
        hover: '#8c8c8c',
        disabled: '#8c8c8c',
      },
    };
  }, []);

  const tableData = useMemo(() => {
    setTotalData(data.length);
    return data;
  }, [data]);

  const tableDataShow = useMemo(() => {
    const newDataList = tableData.filter(
      (_, index) =>
        index >= (selectedPage - 1) * 10 && index < selectedPage * 10
    );
    if (partialData === 0) {
      setPartialData(newDataList.length);
    }
    return newDataList;
  }, [partialData, selectedPage, tableData]);

  const dataToExport = useMemo(() => {
    return tableDataShow;
  }, [tableDataShow]);

  const totalPages = useMemo(() => {
    const pages = Math.ceil(tableData.length / 10);
    return pages;
  }, [tableData]);

  const onChangePage = useCallback(
    (page) => {
      setSelectedPage(page);
      if (page >= 3) {
        if (page <= totalPages - 2) {
          setFirstButton(page - 1);
        } else if (page === totalPages) {
          if (totalPages === 3) {
            setFirstButton(page - 1);
          } else if (totalPages === 4) {
            setFirstButton(page - 2);
          } else {
            setFirstButton(page - 3);
          }
        } else if (totalPages === 4) {
          setFirstButton(page - 1);
        } else {
          setFirstButton(page - 2);
        }
      } else if (page === 1) {
        setFirstButton(page + 1);
      } else {
        setFirstButton(page);
      }

      if (page >= 3) {
        if (page <= totalPages - 2) {
          setCenterButton(page);
        } else if (page === totalPages) {
          if (totalPages === 4) {
            setCenterButton(page - 1);
          } else {
            setCenterButton(page - 2);
          }
        } else if (totalPages === 4) {
          setCenterButton(page);
        } else {
          setCenterButton(page - 1);
        }
      } else if (page === 1) {
        setCenterButton(page + 2);
      } else {
        setCenterButton(page + 1);
      }

      if (page >= 3) {
        if (page <= totalPages - 2) {
          setLastButton(page + 1);
        } else if (page === totalPages) {
          setLastButton(page - 1);
        } else {
          setLastButton(page);
        }
      } else if (page === 1) {
        setLastButton(page + 3);
      } else {
        setLastButton(page + 2);
      }

      setPartialData(10 * page);
    },
    [totalPages]
  );

  const handleChange = useCallback(
    (e) => {
      if (onSearch) {
        setSelectedPage(1);
        onSearch(e.target.value);
      }
    },
    [onSearch]
  );

  const handleChangeStartDate = useCallback(
    (e) => {
      setStartDate(e);
      if (onChangeStartDate) {
        setSelectedPage(1);
        onChangeStartDate(e);
      }
    },
    [onChangeStartDate]
  );

  const handleChangeLastDate = useCallback(
    (e) => {
      setLastDate(e);
      if (onChangeLastDate) {
        setSelectedPage(1);
        onChangeLastDate(e);
      }
    },
    [onChangeLastDate]
  );

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <TableContent
      className={`${
        location.pathname.match('/wallet/') && 'h-100 d-flex flex-column'
      } p-relative px-3 px-lg-4 py-5`}
      showHeader={!!((title && showTitle) || searchable || date || exportable)}
      rowIsClickable={!!onRowClicked}
    >
      <div
        className={`row w-100 p-relative menu-table ${
          location.pathname === '/commission-history' ||
          location.pathname === '/en/commission-history' ||
          location.pathname === '/es/commission-history'
            ? 'justify-content-between'
            : 'justify-content-end'
        } ${
          location.pathname !== '/affiliates/crowdfunding' ? 'mb-n5 pb-4' : ''
        }`}
      >
        {title && showTitle && (
          <>
            <div
              className={
                !searchable && !date && !exportable ? 'col-12' : 'col-lg-12'
              }
            >
              <h2 className="h5">{title}</h2>
            </div>
            <div className="col-lg-1 spacer" />
          </>
        )}
        {searchable && (
          <div className="col-lg-4 my-2 px-0 px-sm-3">
            <div className="border-gray search">
              <div className="w-100 d-flex align-items-center pr-2">
                <input
                  className="w-100"
                  placeholder={language.table.input}
                  onChange={handleChange}
                />
                <img src={magnifier} alt="Magnifier" className="height" />
              </div>
            </div>
          </div>
        )}
        {(date || exportable) && (
          <div
            className={`col-lg-7 my-2 date-and-export px-0 px-sm-3 ${
              location.pathname === '/commission-history' ||
              location.pathname === '/en/commission-history' ||
              location.pathname === '/es/commission-history'
                ? 'col-xl-6 col-xxl-5'
                : 'col-xxl-10'
            } `}
          >
            <div className="d-flex justify-content-between justify-content-lg-end justify-content-wallet w-100">
              {date && (
                <>
                  <div className="my-2 my-lg-0 px-1">
                    <InputDate
                      onChange={handleChangeStartDate}
                      selected={startDate}
                      className="w-100 text-center inputDate size-xxs size-wallet"
                      dateFormat="MM/dd/yyyy"
                    />
                  </div>
                  <div className="my-2 my-lg-0 px-1">
                    <InputDate
                      onChange={handleChangeLastDate}
                      selected={lastDate}
                      className="w-100 text-center inputDate size-xxs size-wallet"
                      dateFormat="MM/dd/yyyy"
                    />
                  </div>
                </>
              )}
              {exportable && (
                <div className="text-center px-1 px-lg-0 mx-lg-1 my-2 my-lg-0">
                  <CSVLink
                    data={dataToExport}
                    separator=";"
                    filename={`${
                      title ? title.replace(/\s/g, '-') : 'data-of-table'
                    }.csv`}
                    className="d-block inputDate svg-xxs w-100px"
                    title={language.table.a}
                  >
                    <img src={table} alt="Table" />
                  </CSVLink>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div
        className={`${
          loading && 'd-none'
        } tableData my-2 mt-lg-0 overflow-auto`}
      >
        <DataTable
          data={tableDataShow}
          columns={columns}
          customStyles={style || customStyles}
          onRowClicked={onRowClicked}
          noDataComponent={
            <NotFound>
              <p className="mb-0 ml-0 ml-lg-3 d-lg-flex align-items-center text-center text-lg-center h5">
                <MdFindInPage size={35} color="#8D8D8D" />
                &nbsp;&nbsp;
                <br className="d-block d-lg-none" />
                {language.table.p_1}
              </p>
            </NotFound>
          }
        />
      </div>
      {pagination && (
        <Pagination className="row pb-1 pt-3 mt-auto w-100 align-items-center justify-content-center menu-table mx-0">
          <div className="col-lg-6 px-lg-4 text-center text-lg-left">
            <p className="mb-lg-0">
              {language.table.p_2_1} {partialData < 10 ? 1 : partialData - 9}-
              {partialData > totalData ? totalData : partialData}{' '}
              {language.table.p_2_2} {totalData}
            </p>
          </div>
          <div className="col-lg-6">
            <div className="d-flex w-100 justify-content-center justify-content-lg-end">
              <button
                type="button"
                className="border-0 bg-transparent mx-1 px-1"
                onClick={() => onChangePage(1)}
                disabled={selectedPage === 1}
              >
                {language.table.button_1}
              </button>
              <button
                type="button"
                className={`border-0 bg-transparent mx-0 mx-sm-0 px-1 btn-page ${
                  selectedPage === 1 ? 'active' : ''
                }`}
                onClick={() => onChangePage(1)}
              >
                01
              </button>
              {totalPages > 1 && (
                <>
                  {totalPages > 5 && (
                    <>
                      {selectedPage > 3 && (
                        <span className="border-0 bg-transparent mx-1 px-1 btn-page">
                          ...
                        </span>
                      )}
                    </>
                  )}
                  {totalPages > 2 && (
                    <button
                      type="button"
                      className={`border-0 bg-transparent mx-1 px-1 btn-page ${
                        selectedPage === firstButton ? 'active' : ''
                      }`}
                      onClick={() => onChangePage(firstButton)}
                    >
                      {`0${firstButton}`.slice(-2)}
                    </button>
                  )}
                  {totalPages > 3 && (
                    <button
                      type="button"
                      className={`border-0 bg-transparent mx-1 px-1 btn-page ${
                        selectedPage === centerButton ? 'active' : ''
                      }`}
                      onClick={() => onChangePage(centerButton)}
                    >
                      {`0${centerButton}`.slice(-2)}
                    </button>
                  )}
                  {totalPages > 4 && (
                    <button
                      type="button"
                      className={`border-0 bg-transparent mx-1 px-1 btn-page ${
                        selectedPage === lastButton ? 'active' : ''
                      }`}
                      onClick={() => onChangePage(lastButton)}
                    >
                      {`0${lastButton}`.slice(-2)}
                    </button>
                  )}
                  {totalPages > 5 && (
                    <>
                      {selectedPage < totalPages - 2 && (
                        <span className="border-0 bg-transparent mx-1 px-1 btn-page">
                          ...
                        </span>
                      )}
                    </>
                  )}
                </>
              )}
              {totalPages > 1 && (
                <button
                  type="button"
                  className={`border-0 bg-transparent mx-1 px-1 btn-page ${
                    selectedPage === totalPages ? 'active' : ''
                  }`}
                  onClick={() => onChangePage(totalPages)}
                >
                  {`0${totalPages}`.slice(-2)}
                </button>
              )}
              <button
                type="button"
                className="border-0 bg-transparent mx-1 px-1"
                onClick={() => onChangePage(totalPages)}
                disabled={selectedPage === totalPages}
              >
                {language.table.button_2}
              </button>
            </div>
          </div>
        </Pagination>
      )}
    </TableContent>
  );
};

export default Table;
