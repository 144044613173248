import { lighten } from 'polished';
import styled from 'styled-components';

interface ICopyAlertProps {
  show: boolean;
}

interface IAvatarProps {
  src: string;
}

export const Container = styled.footer`
  h2 {
    color: #dadada;
  }
  .bg-user {
    background: #242526;
    border-radius: 20px;
  }

  .btn-copy {
    background-color: transparent;
    border: solid 1.5px #828282;
    border-radius: 15px;
    height: 56px;
    color: #cbccce;
    font-weight: 300;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;

    :hover {
      color: ${lighten(0.1, '#cbccce')};
      border: 1.5px solid ${lighten(0.1, '#828282')};
      transition-duration: 0.5s;
      img {
        filter: contrast(7.5);
        transition-duration: 0.5s;
      }
    }

    :disabled {
      pointer-events: none;
    }
  }

  .color-gray {
    color: #828282;
  }

  .skeleton {
    background-color: #343434 !important;
    overflow: hidden;
    position: relative;
    border-radius: 20px;
    border: none !important;
    color: transparent !important;
    pointer-events: none;
    display: inline-block;
    box-shadow: none;

    span,
    input {
      color: transparent !important;
    }
    img {
      display: none;
    }

    button {
      display: none;
    }

    :after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        #343434 0%,
        rgba(24, 26, 26, 0) 0%,
        rgba(24, 26, 26, 0) 10%,
        rgba(24, 26, 26, 0.2) 20%,
        rgba(24, 26, 26, 0.5) 40%,
        rgba(24, 26, 26, 0.8) 50%,
        rgba(24, 26, 26, 0.2) 80%,
        rgba(24, 26, 26, 0) 90%,
        rgba(24, 26, 26, 0) 100%
      ) !important;
      animation: glow 1.3s linear infinite;
    }

    @keyframes glow {
      100% {
        transform: translateX(100%);
      }
    }
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    .w-65 {
      width: 65%;
    }

    .w-40 {
      width: 40%;
    }
  }
`;

export const Profile = styled.div`
  img {
    padding: 3px;
    background-image: linear-gradient(0deg, #202020, #202020),
      radial-gradient(
        circle at top left,
        rgba(228, 228, 228, 0.5),
        rgba(228, 228, 228, 0.5)
      );
    background-origin: border-box;
    background-clip: content-box, border-box;
    border-image-slice: 1;
    border-radius: 50%;
  }

  span {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #ffbb38;
    right: 5px;
    top: 8px;
  }

  p {
    color: rgba(247, 247, 247, 0.5);
  }
`;

export const Avatar = styled.div<IAvatarProps>`
  margin: 0 auto;
  width: 91px;
  height: 91px;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  box-shadow: inset 0px 0px 0px 4px rgba(228, 228, 228, 0.5);
  box-sizing: border-box;
`;

export const Social = styled.div`
  .max-width {
    max-width: 100% !important;
  }
  .titleSocial {
    background: #333;
    border-radius: 20px 20px 0px 0px;
  }

  h4 {
    color: #e4e4e4;
    font-weight: 400 !important;
  }

  p {
    color: #dadada !important;
  }

  .contentSocial {
    background: #242526;
    border-radius: 0 0 20px 20px;
  }
`;

export const BuyGiftCard = styled.div`
  form {
    > div > div {
      border: 1.5px solid #828282 !important;
      border-radius: 15px !important;
    }
    input {
      padding: 6px 0;
    }
    label {
      font-size: 14px;
      font-weight: 400;
      color: #828282;
    }
    button:not(.btn-copy) {
      background-color: #4d4d4d;
      color: #d3d3d3;
      font-size: 14px;
      font-weight: 500;
      border-radius: 15px;
      border: none;
      :hover {
        background-color: ${lighten(0.1, '#4D4D4D')};
        color: ${lighten(0.1, '#D3D3D3')};
        transition-duration: 0.5s;
      }
    }
  }
  .titleBuyGift {
    background: #333;
    border-radius: 20px 20px 0px 0px;
    h4 {
      font-size: 12px;
      font-weight: 500;
    }
  }

  .contentBuyGift {
    background: #242526;
    border-radius: 0 0 20px 20px;
  }
`;

export const GiftCard = styled.div`
  form {
    > div {
      border: 1.5px solid #828282 !important;
      border-radius: 15px !important;
    }

    input {
      padding: 6px 0;
    }

    button:not(.btn-copy) {
      background-color: #4d4d4d;
      color: #d3d3d3;
      font-size: 14px;
      font-weight: 500;
      border-radius: 15px;
      border: none;
      :hover {
        background-color: ${lighten(0.1, '#4D4D4D')};
        color: ${lighten(0.1, '#D3D3D3')};
        transition-duration: 0.5s;
      }
    }
  }

  .titleGift {
    background: #333;
    border-radius: 20px 20px 0px 0px;
    h4 {
      font-size: 12px;
      font-weight: 500;
    }
  }

  .contentGift {
    background: #242526;
    border-radius: 0 0 20px 20px;
  }
`;

export const Introduction = styled.div`
  background-color: rgba(36, 37, 38, 1);
  border-radius: 20px;
  h3 {
    color: rgba(203, 204, 206, 1);
  }
  hr {
    background-color: rgba(51, 51, 51, 1);
  }
  p {
    color: rgba(96, 96, 96, 1);
  }
  button {
    border: none;
    border-radius: 20px;
    background-color: rgba(150, 150, 150, 1);
    color: rgba(24, 25, 26, 1);
  }
`;

export const CopyAlert = styled.div<ICopyAlertProps>`
  transition-duration: 0.3s;
  position: fixed;
  right: ${(props) => (props.show ? '25px' : '-150px')};
  bottom: 50px;
`;
