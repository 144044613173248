import styled from 'styled-components';
import { lighten } from 'polished';

interface loadingStatusProps {
  percentage: number;
}

export const Container = styled.div``;

export const AllCourses = styled.div``;

export const Welcome = styled.div`
  h1 {
    color: #e4e4e4;
  }
  p {
    color: #969696;
  }
`;

export const Content = styled.div`
  background: #202020;
  border-radius: 20px;

  a {
    font-weight: 300;
    color: #8d8f90;
    border: 1px solid #333333;
    border-radius: 16px;
    text-decoration: none !important;
    :hover {
      color: ${lighten(0.1, '#8d8f90')};
      border: 1px solid #8d8f90;
      transition-duration: 0.5s;
    }
  }

  .skeleton {
    background-color: #343434 !important;
    overflow: hidden;
    position: relative;
    border-radius: 20px;
    border: none !important;
    color: transparent !important;
    pointer-events: none;
    display: block;
    box-shadow: none;

    .apexcharts-xaxis {
      display: none !important;
    }

    .apexcharts-text {
      display: none !important;
    }

    span,
    input {
      color: transparent !important;
    }
    img {
      display: none;
    }

    button {
      display: none;
    }

    :after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        #343434 0%,
        rgba(24, 26, 26, 0) 0%,
        rgba(24, 26, 26, 0) 10%,
        rgba(24, 26, 26, 0.2) 20%,
        rgba(24, 26, 26, 0.5) 40%,
        rgba(24, 26, 26, 0.8) 50%,
        rgba(24, 26, 26, 0.2) 80%,
        rgba(24, 26, 26, 0) 90%,
        rgba(24, 26, 26, 0) 100%
      ) !important;
      animation: glow 1.3s linear infinite;
    }

    @keyframes glow {
      100% {
        transform: translateX(100%);
      }
    }
  }

  .title-chart {
    h3,
    p {
      color: #cbccce;
    }
  }

  .bg-gray {
    background: #242526;
    border-radius: 20px;
    padding: 25px;

    h3,
    p {
      color: #cbccce;
    }

    h3 {
      font-size: 18px;
    }

    p {
      font-weight: 300;
    }

    li {
      border-color: #474848 !important;
    }
  }

  .semi-bold {
    font-weight: 600;
  }

  .icons {
    height: 30px;
  }

  .text-green {
    color: #37c694;
  }

  .text-red {
    color: #ff1a50;
  }

  .avatar-coach {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  @media screen and (min-width: 1750px) {
    .d-1750-block {
      display: block !important;
    }

    .d-1750-none {
      display: none !important;
    }

    .text-right {
      p:nth-child(2) {
        font-size: 34px;
      }
    }
  }

  @media screen and (max-width: 1400px) {
    .bg-gray {
      h3 {
        font-weight: 500;
      }
    }
  }
`;

export const Search = styled.div`
  background-color: #303030;
  border: 1px solid #333333;
  border-radius: 25px;
  height: 41px;

  input {
    margin: 5px 0;
    border: none;
    background-color: transparent;
    color: #cbccce;
  }
`;

export const Graph = styled.div`
  background: #242526;
  border-radius: 20px;
  padding: 25px;
`;

export const TopCoach = styled.div`
  background: #202020;
  border-radius: 20px;

  .btn-month {
    background: #242526;
    border: 1px solid rgba(225, 225, 225, 0.2);

    span {
      color: #8c8c8c;
    }
  }

  .avatar {
    width: 90px;
    height: 90px;
    padding: 3px;
    background-image: linear-gradient(0deg, #202020, #202020),
      linear-gradient(180deg, #825511 0%, #f3bf5c 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    border-image-slice: 1;
    border-radius: 50%;
  }
`;

export const TopAfiliate = styled.div`
  background: #202020;
  border-radius: 20px;

  .btn-month {
    background: #242526;
    border: 1px solid rgba(225, 225, 225, 0.2);

    span {
      color: #8c8c8c;
    }
  }

  .avatar {
    width: 90px;
    height: 90px;
    padding: 3px;
    background-image: linear-gradient(0deg, #202020, #202020),
      linear-gradient(180deg, #825511 0%, #f3bf5c 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    border-image-slice: 1;
    border-radius: 50%;
  }
`;
