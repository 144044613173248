import React, { useCallback, useEffect, useState } from 'react';
import { format, subMonths, getUnixTime } from 'date-fns';
import Swal from 'sweetalert2';

import { formatPrice } from '~/utils/format';
import api from '~/services/api';
import { useAuth } from '~/hooks/Auth';

import { Container } from './styles';
import ModalRetireMoney from '../ModalRetireMoney';
import { useLanguage } from '~/hooks/Language';

interface ICommission {
  id: string;
  earning: string;
  amount_paid: string;
  created_at: string;
}

interface IMonth {
  name: string;
  selected: boolean;
}

interface IWallet {
  id: string;
  amount: number;
  cripto_address: string;
}

interface IGiftCards {
  price: number;
}

interface IMyEarnings {
  updatePage?(): void;
}

const MyEarnings: React.FC<IMyEarnings> = ({ updatePage }) => {
  const { walletPrice, setWalletPrice, user } = useAuth();
  const [months, setMonths] = useState<IMonth[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [allGains, setAllGains] = useState('$0.00');
  const [availableWithdrawal, setAvailableWithdrawal] = useState('$0.00');
  const [wallet, setWallet] = useState<IWallet>({} as IWallet);
  const { language } = useLanguage();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    api.get('commissions').then(async (response) => {
      const gains = response.data.reduce(
        (acumulador: number, currentValue: ICommission) => {
          return acumulador + parseFloat(currentValue.earning);
        },
        0
      );

      const currentDate = new Date();
      const monthArray: IMonth[] = [];
      for (let i = 5; i >= 0; i -= 1) {
        const month = {
          name: format(subMonths(currentDate, i), 'MMM'),
          selected:
            getUnixTime(subMonths(currentDate, i)) === getUnixTime(currentDate),
        };
        monthArray.push(month);
      }

      setMonths(monthArray);

      setAllGains(formatPrice(gains));
    });
  }, []);

  useEffect(() => {
    api
      .get('commissions', {
        params: {
          type: 'available',
        },
      })
      .then(async (response) => {
        const gains = response.data.reduce(
          (acumulador: number, currentValue: ICommission) => {
            return acumulador + parseFloat(currentValue.earning);
          },
          0
        );

        const responseGiftCards = await api.get<IGiftCards[]>(
          'gift-cards/students'
        );

        const newGains = responseGiftCards.data.reduce(
          (previousValue, currentValue) => {
            return previousValue - currentValue.price;
          },
          gains
        );

        const responseWallet = await api.get('wallets/affiliates');

        if (responseWallet.data) {
          setWallet({ ...responseWallet.data, amount: newGains });
          setWalletPrice(newGains);
        }
      });
  }, [setWalletPrice]);

  useEffect(() => {
    setAvailableWithdrawal(formatPrice(walletPrice));
  }, [walletPrice]);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        const formData = {
          wallet_id: wallet.id,
          amount: data.amount,
          cripto_address: data.cripto_address,
          token_2fa: data.token_2fa,
        };
        const response = await api.post('scheduled-withdrawals', formData);
        if (response.data) {
          const newWallet = {
            id: wallet.id,
            amount: wallet.amount - data.amount,
            cripto_address: data.cripto_address,
          };
          if (updatePage) {
            updatePage();
          }
          setWallet(newWallet);
          setWalletPrice(newWallet.amount);

          Swal.fire(
            language.wallet.modal_success_1,
            language.wallet.modal_success_2,
            'success'
          ).then(() => {
            setShowModal(false);
          });
        }
      } catch (error) {
        const err = error as any;
        if (err.response && err.response.data) {
          if (err.response.data.message === 'Code is incorrect') {
            Swal.fire(
              'Opss...',
              language.wallet.modal_authentication_2,
              'error'
            );
          } else if (
            err.response.data.message === 'Value greater than available value'
          ) {
            Swal.fire('Opss...', language.wallet.modal_value_3, 'error').then(
              () => {
                setShowModal(false);
              }
            );
          } else {
            Swal.fire('Opss...', language.wallet.modal_error, 'error');
          }
        } else {
          Swal.fire('Opss...', language.wallet.modal_error, 'error');
        }
      }
    },
    [
      language.wallet.modal_authentication_2,
      language.wallet.modal_error,
      language.wallet.modal_success_1,
      language.wallet.modal_success_2,
      language.wallet.modal_value_3,
      setWalletPrice,
      updatePage,
      wallet.amount,
      wallet.id,
    ]
  );

  const handleClickWithdraw = useCallback(async () => {
    await api.post(`students/2fa/${user.id}`);
    setShowModal(true);
  }, [user.id]);

  return (
    <Container className="p-3 py-5 py-xxl-0 h-100">
      <div className="row justify-content-center">
        <div className="col-lg-12">
          <h5 className="TitleBox my-4 font-weight-600">
            {language.wallet.h5}
          </h5>
        </div>
        <div className="col-lg-12">
          <span
            className={`${loading && 'skeleton px-2'} ValueBox font-weight-600`}
          >
            {allGains}
          </span>
        </div>

        <div className="col-lg-12 TimeBox mb-3">
          <div className="d-flex justify-content-around">
            <button
              type="button"
              className="border-0 bg-transparent ItemTimeBox"
            >
              {language.wallet.button_1}
            </button>
            <button
              type="button"
              className="border-0 bg-transparent ItemTimeBox"
            >
              {language.wallet.button_2}
            </button>
            <button
              type="button"
              className="border-0 bg-transparent ItemTimeBox"
            >
              {language.wallet.button_3}
            </button>
            <button
              type="button"
              className="border-0 bg-transparent ItemTimeBox Active"
            >
              {language.wallet.button_4}
            </button>
          </div>
        </div>
        {/* <div className="col-lg-12 MonthBox">
          <div className="d-flex justify-content-between">
            {months.map((month) => (
              <button
                key={month.name}
                type="button"
                className={`border-0 bg-transparent w-100 rounded-pill ItemMonthBox ${
                  month.selected ? 'Active' : ''
                }`}
              >
                {month.name}
              </button>
            ))}
          </div>
        </div> */}

        <div className="col-10 mb-xxl-4 p-3 py-4 WithDrawBox">
          <div className="row justify-content-center">
            <div className="col-12 text-center">
              <div className="row">
                <div className="col-12 mb-3 TitleWithDrawBox mb-2">
                  <small>{language.wallet.small_1}</small>
                </div>
              </div>
              <div className="ValueWithDrawBox my-2 my-sm-0">
                <span className={`${loading && 'skeleton px-2'} h4`}>
                  {availableWithdrawal}
                </span>
              </div>
            </div>
            <div className="col-6 mt-3 px-3 pl-lg-0 px-xl-3 ButtonBox">
              <button
                className="small btn rounded-pill ButtonWithDrawBox"
                type="button"
                onClick={handleClickWithdraw}
              >
                {language.wallet.button_5}
              </button>
              <ModalRetireMoney
                show={showModal}
                onHide={() => setShowModal(false)}
                wallet={wallet}
                onSubmit={handleSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default MyEarnings;
